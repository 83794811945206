// 20200611 START 박지영 - addToCart 를 전역변수로 변경

/* LGEGMC-407 : 20200709 add */
var OBS, addToCart, ga360Init, _salesModelCode, _modelCode , ga360CompareInit , ga360SearchInit , ga360plpInit;
/*// LGEGMC-407 : 20200709 add */


var reStockAlert;

// 20200611 END
$(document).ready(function() {
	console.log('add-to-cart.js', 'OBS_LOGIN_FLAG : ', OBS_LOGIN_FLAG);
	_salesModelCode	= (typeof _dl.products === "undefined" ||  typeof _dl.products[0] === "undefined") ? '' : _dl.products[0].sales_model_code;
	_modelCode = (typeof _dl.products === "undefined" || typeof _dl.products[0] === "undefined") ? '' : _dl.products[0].model_code;
	
	var currentFocus;
	
	/* LGEGMC-1914 START */
	var $component = $('.GPC0009');
	/* LGEGMC-1914 END */
	
	// OBSLGETW-478 Start
	var url = new URL(window.location.href);
	var urlParams = url.searchParams;
	var shopbackUtmSourceCookie = getCookie('shopback_utm_source');
	var shopbackSbidCookie = getCookie('shopback_sbid');
	if(COUNTRY_CODE.toLowerCase() == 'tw' && urlParams.has('utm_source') && urlParams.has('sbid') ){
		removeCookie('shopback_utm_source', true);
		removeCookie('shopback_sbid', true);
		if(urlParams.get('utm_source') != '' && urlParams.get('sbid') != ''){
			setCookie('shopback_utm_source', urlParams.get('utm_source'), true);
			setCookie('shopback_sbid', urlParams.get('sbid'), true);
		}
	} else{
		if(shopbackUtmSourceCookie == '' || shopbackSbidCookie == ''){
			removeCookie('shopback_utm_source', true);
			removeCookie('shopback_sbid', true);
		}
	}
	// OBSLGETW-478 End
	
	var OBS_Y = {
		changeCartUrl: function(cartID) {
			if(cartID) {
				var url = addToCart.$nav.find('.for-desktop .right-btm .icons .cart a').attr('href');
				if(url) {
					if(url.indexOf('?')!=-1) {
						url=url.split('?')[0]+'?cartId='+cartID;
					} else {
						url=url+'?cartId='+cartID;
					}
					addToCart.$nav.find('.for-mobile .right .cart a, .for-desktop .right-btm .icons .cart a').attr('href', url);
				}
			}
		},
		checkLogin: function() {
			var isLogin = false;
			if(addToCart.$nav.find('.logged .after-login').length>0) {
				isLogin = true;
			}
			return isLogin;
		},
		getToken: function() {
			/* LGEITF-376 modify */
			var authToken = sessionStorage.getItem('ACCESS_TOKEN');
			
			return authToken;
		},
		createEmptyCart: function(modelId) {
			var authToken = OBS_Y.getToken();
			var url = addToCart.url;
			var headers = {};
			if(authToken != '') {
				headers.Authorization = "Bearer " + authToken;
			}
			headers.Store = addToCart.store;
			headers["Content-type"] = "application/json";
			
			var pinModelId = $component.find("#pin-modelId").val();
			var addToCartInfo = '';
			if($('.add-to-cart').length > 0){
				addToCartInfo = $('.add-to-cart:eq(0)');
			}
			
			$.ajax({
				url: url,
				headers: headers,
				type: 'post',
				data: JSON.stringify({'query': 'mutation {createEmptyCart}', 'variables':null, 'operationName':null}),
				dataType: 'json',
				success: function(data) {
					addToCart.cartID = data.data.createEmptyCart;
					/*LGEGMC-1210 s*/
					//if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {
						setCookie(addToCart.cookieName, addToCart.cartID, true);
					//}
					/*LGEGMC-1210 e*/
					// add param to cart url
					
					//LGEITF-602 START : CART URL CART ID 추가 부분 제거
					/*if(addToCart.cartID) {
						OBS_Y.changeCartUrl(addToCart.cartID);
					}*/
					//LGEITF-602 END
					
					if(pinModelId){
						if($component.find(".check-good").length>0){
							
							/* LGEGMC-407 : 20200709 modify */
							OBS_Y.addProductToCart(modelId, 1, addToCart.cartID, true, addToCartInfo);
							/*// LGEGMC-407 : 20200709 modify */
							
						}else{
							
							/* LGEGMC-1914 modify */
							if($component.find('.checkAvailable').length > 0){
								$('#comunaRegionId').trigger('chosen:activate');
								$component.find(".check-region").focus();
							} else {
								if($(".GPC0009").hasClass("before")){
									if($component.find(".delivery-list .option-list").length > 0) {
										$('#pinCodeList').trigger('chosen:activate');
										$component.find(".check-pincode").focus();
									} else {
										$component.find(".check-pincode").focus();
									}
								}else if($(".GPC0009").hasClass("improve")){
									if($component.find(".checkStock-list .option-list").length > 0) {
										$('#pinCodeList').trigger('chosen:activate');
										$component.find(".check-pincode").focus();
									} else {
										$component.find(".check-pincode").focus();
									}
								}
							}
							$('body').trigger('ajaxLoadEnd');
							/* LGEGMC-1914 modify */
							
						}
					} else {
						/* LGEGMC-712 : 20200928 modify */
						var datelayer_use_flag = $('.addtocart-datelayer-use-flag').val();
						if(datelayer_use_flag == 'Y'){
							OBS_Y.addProductToCart(modelId, 1, addToCart.cartID, true, addToCartInfo);
						} else{		
							//add to cart
							if(modelId) OBS_Y.addProductToCart(modelId, 1, addToCart.cartID);
							}
						/* LGEGMC-712 : 20200928 modify */

					}

				},
				error: function(request, status, error) {
					//console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
				}
			});
		},

		addProductToCart: function(model, qty, cartid, ga360, _obj, pinCode) {
			console.log('addProductToCart start'); // test
			console.log('model');
			console.log(model);
			console.log('qty');
			console.log(qty);
			console.log('cartid');
			console.log(cartid);
			console.log('ga360');
			console.log(ga360);
			console.log('_obj');
			console.log(_obj);

			if(!model) return false;

			// LGEGMC-1126 Start
			var modelSplit = model.split(',');
			var packageItemId = [];

			for(var i=1; i<modelSplit.length; i++){
				packageItemId[i-1]=modelSplit[i].trim();
			}
			// LGEGMC-1126 End
			var authToken = OBS_Y.getToken();
			var url = addToCart.url;
			var headers = {};
			if(authToken != '') {
				headers.Authorization = "Bearer " + authToken;
			}
			
			//LGEGMC-2851 START
			let obsReferrerFlag = $('.navigation').data('obs-referrer-flag');
			let refererDomain = "";
			if(obsReferrerFlag == 'Y'){
				refererDomain = sessionStorage.getItem('referrerDomain');
			}
			//OBSLGECZ-291 START
			var marketplaceName = "";
			if(document.referrer != ''){
				marketplaceName = getMktDomainUrl(document.referrer);
			}
			var pageType = (typeof standardData === "undefined") ? '' : standardData.pageType;
			//OBSLGECZ-291 END
			
			//LGCOMSM-34, LGEIN-586, LGEAU-586, OBSLGECZ-291, OBSLGETW-478, LGEGMCOBS-74, OBSLGEIN-564 START
			var cartData;
			var shopbackUtmSourceCookie = getCookie('shopback_utm_source');
			var shopbackSbidCookie = getCookie('shopback_sbid');
			if(obsReferrerFlag == 'Y'){
				cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'" } ],referrerDomain: "'+refererDomain+'" }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'sa' || addToCart.store == 'sa_en'){
				cartData =JSON.stringify({'query': 'mutation { addModelToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'au'){
				cartData =JSON.stringify({'query': 'mutation { addModelToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'", zipcode: "'+pinCode+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'br'){
				cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, zipcode: "'+pinCode+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'cz' && pageType == 'PRODUCT'){
				cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'", marketplaceName: "'+marketplaceName+'"} ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'tw' && shopbackUtmSourceCookie != '' && shopbackSbidCookie != ''){
				cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'" } ], utm_source:"'+shopbackUtmSourceCookie+'" , sbid:"'+shopbackSbidCookie+'"}) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else if(addToCart.store == 'mx'){
				if(!!pinCode){//LGCOMMS-99
					cartData =JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'", zipcode: "'+pinCode+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
				}else{
					cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
				}
			}else if(addToCart.store == 'in'){
				cartData =JSON.stringify({'query': 'mutation { addModelToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'", zipcode: "'+pinCode+'" } ] }) {redirectUrl cart { quote {quote_id} items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}else{
				cartData = JSON.stringify({'query': 'mutation { addModelToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}, package_item_id: "'+packageItemId+'" } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
			}
			//LGCOMSM-34, LGEIN-586, LGEAU-586, OBSLGECZ-291, OBSLGETW-478, LGEGMCOBS-74, OBSLGEIN-564 END
			//LGEGMC-2851 END
			
			headers.Store = addToCart.store;
			headers["Content-type"] = "application/json";
			
			//PJTEXTENDEDWTY-5 ew 스텐드 얼론 구매 쿼리 추가 
			var ewSaveData = {};
			if('undefined' != typeof $('[data-ew-save-data]').data()){
				ewSaveData = $('[data-ew-save-data]').data();
			} 
			var isEwProduct = ewSaveData.isEwProduct;
			if(isEwProduct == 'Y'){
				//query
				var additional_attributes = {};
				additional_attributes.sales_model_name = ewSaveData.sales_model_name;
				additional_attributes.serial_no = ewSaveData.serial_no;
				additional_attributes.company_code = ewSaveData.company_code;
				additional_attributes.company_name = ewSaveData.company_name;
				var purchasedDateFormat = $('.extended-warranty .option-form').find('input[name="purchasedDate"]').data('date-format');
				if(!!purchasedDateFormat){
				    additional_attributes.purchase_date = getFormatDate(ewSaveData.purchase_date,purchasedDateFormat,'YYYY-MM-DD'); 
				}
				additional_attributes.purchase_price = ewSaveData.purchase_price;
				var additional_attributesStr = JSON.stringify(additional_attributes);
				var additional_attributesArr = [];
				additional_attributesArr.push("{");
				additional_attributesArr.push("sales_model_name :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.sales_model_name);additional_attributesArr.push("\"");
				additional_attributesArr.push(",serial_no :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.serial_no);additional_attributesArr.push("\"");
				additional_attributesArr.push(",company_code :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.company_code);additional_attributesArr.push("\"");
				additional_attributesArr.push(",company_name :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.company_name);additional_attributesArr.push("\"");
				additional_attributesArr.push(",purchase_date :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.purchase_date);additional_attributesArr.push("\"");
				additional_attributesArr.push(",purchase_price :");additional_attributesArr.push("\"");additional_attributesArr.push(additional_attributes.purchase_price);additional_attributesArr.push("\"");
				additional_attributesArr.push("}");
				
				if(ewSaveData.pin_code!=null&&ewSaveData.pin_code!=''){
					cartData = JSON.stringify({'query': 'mutation { addExtendedWarrantyToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+', pincode: "'+ewSaveData.pin_code+'"} } ],productinfo:'+additional_attributesArr.join('')+' }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null}); 
				}else{
					cartData = JSON.stringify({'query': 'mutation { addExtendedWarrantyToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+'} } ],productinfo:'+additional_attributesArr.join('')+' }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null}); 
				}
			}
			//PJTEXTENDEDWTY2 add on test
			if($component.find('.care-select').find('a.act').length > 0){
				var extendedWtyCode = $component.find('.care-select').find('a.act').data('extended-wty-code');
				console.log("extendedWtyCode:"+extendedWtyCode);
				if(addToCart.store == 'in' || addToCart.store == 'au' || addToCart.store == 'mx'){		// LGEMS-491 add mx
					if(addToCart.store == 'mx' && !pinCode){
						cartData = JSON.stringify({'query': 'mutation { addExtendedWarrantyToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+',package_item_id:"'+extendedWtyCode+'"} } ], }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
					}else{
						cartData = JSON.stringify({'query': 'mutation { addExtendedWarrantyToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+',package_item_id:"'+extendedWtyCode+'", pincode: "'+pinCode+'"} } ], }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
					}
					
				}else{
					cartData = JSON.stringify({'query': 'mutation { addExtendedWarrantyToCart(input: { cartItems: [ { data: { sku: "'+modelSplit[0]+'", qty: '+qty+',package_item_id:"'+extendedWtyCode+'"} } ], }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
				}
					
			}
			//PJTEXTENDEDWTY-5 ew 스텐드 얼론 구매 쿼리 추가 
			//PJTOBJET-5
			if($('.GPC0009 .objet-selectPart-list li:visible').length>0){
				var hasProduct = $(".pdp-parts-choice").hasClass('product');
				var cartParam = '';
				if(hasProduct){
					cartParam = '{ data: { sku: "'+modelSplit[0]+'", qty: '+qty+'}}';
				}
				var viewArea = $(".mobile-bottom-info").is(":visible") ? '.mobile-bottom-info' : '.desktop-info';
				$(viewArea + ' .objet-selectPart-list li:visible').each(function(i,l){
					var length = $(viewArea + ' .objet-selectPart-list li:visible').length;
					var el = $(l).find('.part-info')
					/**var texture = el.attr('data-texture');
					var color= el.attr('data-color');
					*/
					var select_parts = el.attr('data-part');
					var panelModelID = el.attr('data-panel-model-id');
					var salesModelCode = el.attr('data-sales-model-code');
					if(hasProduct){
						cartParam = cartParam +',{data: { sku: "'+panelModelID+'", qty: 1 } lg_custom_options: { objet_sales_options: "'+salesModelCode+','+select_parts+'" parent_sku: "'+modelSplit[0]+'" }}';
					}else{
						cartParam = cartParam +'{data: { sku: "'+panelModelID+'", qty: 1 } lg_custom_options: { objet_sales_options: "'+salesModelCode+','+select_parts+'"}}';
						if(i<length-1){
							cartParam = cartParam +',';
						}
					}
				})			
				cartData = JSON.stringify({'query': 'mutation { addObjetSalesToCart(input: { cartItems: [ '+cartParam+' ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null});
				console.log("Objet-Cart Data:"+cartData);
			}
			//PJTOBJET-5 objet 모델 선택되어있을 경우 
			
			//PJTPKGDEAL-4 Package Deal 추가
			if ($(".GPC0162 .btn-add-basket").hasClass("package-deal")) {
                var packageDealParam    = "";
                
                for (var i=0; i<modelSplit.length; i++) {
                    packageDealParam += ' { data : { sku: "' + modelSplit[i] + '", qty: ' + qty + ' }, package_item_id: "' + '" },';
                }
                
                packageDealParam = packageDealParam.substring(0, packageDealParam.length - 1);
                cartData  = JSON.stringify({'query' : 'mutation { addModelToCart(input: { cartItems: [' + packageDealParam + ' ] }) { redirectUrl cart { items { product { sku } qty } } } }', 'variables':null, 'operationName':null});
            }
            //PJTPKGDEAL-4 Package Deal 추가
            
            //PJTPPDPAOB-1 start addOn 번들
             if($('.GPC0009 .add-bundle-area .bundle-list li .act').length > 0){
				 var addOnBundelParam = "";
				 for (var i=1; i<modelSplit.length; i++) {
                    addOnBundelParam += '{ sku: "' + modelSplit[i] + '" },';
                 }
                 addOnBundelParam = addOnBundelParam.substring(0, addOnBundelParam.length - 1);
                 addOnBundleModel = modelSplit[0];
                 //LGCOMAU104 Start
                 if(addToCart.store == 'au'){
                	 cartData  = JSON.stringify({'query' : 'mutation { addAddonBundlePackageToCart(input: { cart_id: "", cartItems:{data:{sku:"'+addOnBundleModel+'",package_item:[' + addOnBundelParam + ' ]}, zipcode: "'+pinCode+'"}}){cart{items{product{sku}qty}}}}'});
                 }else{
                	 cartData  = JSON.stringify({'query' : 'mutation { addAddonBundlePackageToCart(input: { cart_id: "", cartItems:{data:{sku:"'+addOnBundleModel+'",package_item:[' + addOnBundelParam + ' ]}}}){cart{items{product{sku}qty}}}}'});
                 }
                 //LGCOMAU104 End               	 
				 //                                    mutation { addAddonBundlePackageToCart(input: { cart_id:"" , cartItems:{data:{sku:"MD07546706",package_item:[{sku:"MD07546799"},{sku:"MD07546798"}]},zipcode:"2000"}}){cart{items{product{sku}qty}}}}
				 
			 }
            //PJTPPDPAOB-1 end addOn 번들 
             
				// single, multi (with comma, GPC0010)
				$.ajax({
					url: url,
					headers: headers,
					type: 'post',
					data: cartData,
					// data: JSON.stringify({'query': 'mutation { addModelToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+model+'", qty: '+qty+'} } ] }) {redirectUrl cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null}),
					// data: JSON.stringify({'query': 'mutation { addModelToCart(input: { cart_id: "'+cartid+'", cartItems: [ { data: { sku: "'+model+'", qty: '+qty+'} } ] }) { cart { items { product { sku } qty}}}}', 'variables':null, 'operationName':null}),
					dataType: 'json',
					success: function(data) {
						if(data.errors) {
							/* LGCOMSM-34 ,LGESA-330 createEmptyCart 로직삭제 */ 
								var errorMsg = '';
								for(var i=0;i<data.errors.length;i++) {
									errorMsg = errorMsg + data.errors[i].message;
								}
								//console.log(errorMsg);
								if($('#addToCartError').length>0) $('#addToCartError').remove();
								var html = '<div class="modal modal-simple fade" id="addToCartError" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Fail to add Cart" aria-describedby="addToCartFailDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartFailDialog">'+errorMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" data-dismiss="modal">'+addToCart.successBtnClose+'</button></div></div></div></div>'; /* LGEGMC-243 20200521 modify */
								
								$('body').append(html);
								$('#addToCartError').modal();
								$('#addToCartError').find('button[data-dismiss=modal]').on('click.close', function() {
									addToCart.currentFocus();
								});
						} else {
							var items = null;
							var len = 0;
							if(data.data.addModelToCart && data.data.addModelToCart.cart && data.data.addModelToCart.cart.items){
								items = data.data.addModelToCart.cart.items;
								len = items.length;
								if(len>0) {
									var product = data.data.addModelToCart.cart.items[len-1].product.sku;
									if(typeof OBS.addCookieValue=='function') OBS.addCookieValue('CART', product);
								}
							}
							if(data.data.addExtendedWarrantyToCart && data.data.addExtendedWarrantyToCart.cart && data.data.addExtendedWarrantyToCart.cart.items){
								items = data.data.addExtendedWarrantyToCart.cart.items;
								len = items.length;
								if(len>0) {
									var product = data.data.addExtendedWarrantyToCart.cart.items[len-1].product.sku;
									if(typeof OBS.addCookieValue=='function') OBS.addCookieValue('CART', product);
								}
							}
							if(data.data.addObjetSalesToCart && data.data.addObjetSalesToCart.cart && data.data.addObjetSalesToCart.cart.items){
								items = data.data.addObjetSalesToCart.cart.items;
								len = items.length;
								if(len>0) {
									for(var j=0;j<len;j++) {
										var product = items[j].product.sku;
										if(typeof OBS.addCookieValue=='function') OBS.addCookieValue('CART', product);
									}
								}
							}
							//PJTPPDPAOB-1 START
							if(data.data.addAddonBundlePackageToCart && data.data.addAddonBundlePackageToCart.cart && data.data.addAddonBundlePackageToCart.cart.items){
								items = data.data.addAddonBundlePackageToCart.cart.items;
								len = items.length;
								if(len>0) {
									var product = data.data.addAddonBundlePackageToCart.cart.items[len-1].product.sku;
									if(typeof OBS.addCookieValue=='function') OBS.addCookieValue('CART', product);
								}
							}
							//PJTPPDPAOB-1 END
							
							//OBSLGEIN-564 START
							if(typeof $('#cartQuoteId') != "undefined") {
								$('#cartQuoteId').val('');
							}
							if(data.data.addModelToCart && data.data.addModelToCart.cart && data.data.addModelToCart.cart.quote && data.data.addModelToCart.cart.quote.quote_id){
								var quote_id = data.data.addModelToCart.cart.quote.quote_id;
								if(typeof $('#cartQuoteId') != "undefined") {
									$('#cartQuoteId').val(quote_id);
								}
							}
							//OBSLGEIN-564 END
							
							/* LGEGMC-407 : 20200709 add */
							if (ga360) {
								//ga360Init.init();
								
								var productsObj = new Object();
								var productDetailArray = new Array();
								
								
							 //LGEGMC-824 ADD
	                          if(addToCart.$nav.find('.logged .after-login').length>0){
	                        	  ga360Init.cartID = cartid;
	                            }
	                         //LGEGMC-824 ADD
	                          // LGEGMCGA-35 Start
	                          if($('.GPC0162').length>0){
	                        	  	$('.after-item').each(function(){
	                        	  		var liArea = $(this).closest("li");
	                        	  		productsObj = {};
	                        	  		if(!$(this).hasClass('d-none')){
											productsObj.id = liArea.attr('data-sku');
											productsObj.name = liArea.attr('data-model-salesmodelcode');
											
											var price = nvl(liArea.attr('data-price') ,'0');
											productsObj.price = price
								            
								            productsObj.brand = liArea.attr('data-buname-two');
								            productsObj.category = liArea.attr('data-buname-one');
								            
								            if(typeof liArea.attr('data-model-salesmodelcode') != "undefined"){
								            productsObj.variant = (liArea.attr('data-model-salesmodelcode').indexOf('.') == -1) ? null : liArea.attr('data-model-salesmodelcode').substring(liArea.attr('data-model-salesmodelcode').indexOf('.')+1, liArea.attr('data-model-salesmodelcode').length);
								            }
								            
											productsObj.quantity = 1;
											
								            productsObj.dimension124 = liArea.attr('data-buname-three');
								            productsObj.dimension125 = liArea.attr('data-super-category-name');
								            productsObj.dimension126 = liArea.attr('data-model-id');
								            productsObj.dimension127 = liArea.attr('data-model-name');
								            
								            ga360Init.ecommerce.currencyCode = ga360Init.currencyCode;
								            
								            productsObj.dimension185 = ga360Init.obsGroup;
								            productsObj.metric4 = liArea.attr('data-msrp');
								            productsObj.cart_btn = ga360Init.cart_btn
								            
								            productDetailArray.push(productsObj);
	                        	  		}
	                        	  	})
								}else{
									productsObj.id = ga360Init.sku;
									productsObj.name = ga360Init.name;
									
									var price = nvl(ga360Init.price ,'0');
									productsObj.price = price
						            
						            productsObj.brand = ga360Init.std_lv2;
						            productsObj.category = ga360Init.std_lv1;
						            
						            //var hasColor = (typeof $('.GPC0009 .swatch.active').prop("classList") !== "undefined") ? $('.swatch.active').prop("classList").item(1) : null;
						            //productsObj.variant = hasColor;
						            if(typeof ga360Init.name != "undefined"){
						            productsObj.variant = (ga360Init.name.indexOf('.') == -1) ? null : ga360Init.name.substring(ga360Init.name.indexOf('.')+1, ga360Init.name.length);
						            }
									if(len>0) {
										productsObj.quantity = 1;
									}
						            
						            productsObj.dimension124 = ga360Init.std_lv3;
						            productsObj.dimension125 = ga360Init.superCategory;
	
						            productsObj.dimension126 = ga360Init.modelCode;
						            productsObj.dimension127 = ga360Init.displayName;
						            
						            ga360Init.ecommerce.currencyCode = ga360Init.currencyCode;
						            
						            productsObj.dimension185 = ga360Init.obsGroup;
						            productsObj.metric4 = ga360Init.metric4;
						            productsObj.cart_btn = ga360Init.cart_btn;
						            console.log(productsObj);
						            productDetailArray.push(productsObj);
								}
	                          	// LGEGMCGA-35 End
					            ga360Init.ecommerce.add = new Object();
					            ga360Init.ecommerce.add.products = productDetailArray;
					            
					            ga360Init.addData();
					            ga360Init.cartID.add
					            
					            //LGEIN-155
					            /* LGEGMC-712 : 20200928 modify */
					            //PJTOBSB2E-3 Start  
					            var modelId = !!$(this).attr('data-model-id') ? $(this).attr('data-model-id') : $(this).data('modelId'); // LGEGMC-5131 
					            var atcBtn = $(_obj).closest('.button').find('.pre-order');
					            var isGnb = $('#navigation_search');
					            var pageName = _dl.page_name.page_purpose;
					            var componentId = '';
					            
					            if(pageName == 'others' || pageName == 'search' || pageName == 'compare'){
					            	componentId = pageName;
					            }else if(isGnb.length>0){
					            	componentId = 'navigation search';
					            }else if( $(_obj).closest('.component').length>0 ){
				            		componentId = $(_obj).closest('.component').attr('class').split(' ')[1];
					            }
					            	
					            if($('.GPC0009').length>0) {
									var pid2 = $('.GPC0009').data('product-id');
									if(atcBtn.length > 0){
										var startDate = $(_obj).data('obs-pre-order-start-date');
										var endDate = $(_obj).data('obs-pre-order-end-date');
										var period = startDate + ' - ' + endDate;
										adobeTrackEvent('pre-order', {
											products: [{
												sales_model_code : findSalesModel($(_obj)),
												model_name: findModelName($(_obj)),
												value_added_to_cart: findPrice($(_obj)),
												units_added_to_cart: findModelCount($(_obj))
											}], component_id: componentId, pre_order_period: period, page_event: {pre_order: true}
										});
									}else{
										if(pid2 != modelId) {
											adobeTrackEvent('add-to-cart', {
												products: [{
													sales_model_code : findSalesModel($(_obj)), /* LGEGMC-455 20200722 modify */
													model_name: findModelName($(_obj)),
													value_added_to_cart: findPrice($(_obj)),
													units_added_to_cart: findModelCount($(_obj))
												}], cross_sell_product :pid2, page_event: {add_to_cart: true}
											});
										} else {
											adobeTrackEvent('add-to-cart', {
												products: [{
													sales_model_code : findSalesModel($(_obj)), /* LGEGMC-455 20200722 modify */
													model_name: findModelName($(_obj)),
													value_added_to_cart: findPrice($(_obj)),
													units_added_to_cart: findModelCount($(_obj))
												}], page_event: {add_to_cart: true} 
											});
										}
									}
								} else {
									if(atcBtn.length > 0){
										var startDate = $(_obj).data('obs-pre-order-start-date');
										var endDate = $(_obj).data('obs-pre-order-end-date');
										var period = startDate + ' - ' + endDate;
										adobeTrackEvent('pre-order', {
											products: [{
												sales_model_code : findSalesModel($(_obj)),
												model_name: findModelName($(_obj)),
												value_added_to_cart: findPrice($(_obj)),
												units_added_to_cart: findModelCount($(_obj))
											}], component_id: componentId, pre_order_period: period, page_event: {pre_order: true}
										});
									}else{
										adobeTrackEvent('add-to-cart', {
											products: [{
												sales_model_code : findSalesModel($(_obj)), /* LGEGMC-455 20200722 modify */
												model_name: findModelName($(_obj)),
												value_added_to_cart: findPrice($(_obj)),
												units_added_to_cart: findModelCount($(_obj))
											}], page_event: {add_to_cart: true}
										});
									}
								}
					            //PJTOBSB2E-3 End
					            

							}
							/*// LGEGMC-407 : 20200709 add */
							
							if((data.data.addModelToCart && !data.data.addModelToCart.redirectUrl)||(data.data.addObjetSalesToCart && !data.data.addObjetSalesToCart.redirectUrl)) {
								// GNB cart number +
								var cnt = 0;
								if(len>0) {
									for(var j=0;j<len;j++) {
										cnt = cnt + parseInt(items[j].qty);
									}
								}
								addToCart.setCartNum(cnt);
								
								//PJTOBSB2E-3 add
								var atcBtn = $(_obj).closest('.button').find('.pre-order');
								var successMsg = '';
								if(atcBtn.length > 0){
									successMsg = addToCart.preOrderSuccessMsg;
								}else{
									successMsg = addToCart.successMsg;
								}
								//PJTOBSB2E-3 add
								/*LGEUK-462, LGEGMC-2772, LGEGMC-2825 S*/
								var noticeIconClass ='';
								if(COUNTRY_CODE=='uk' || COUNTRY_CODE=='pl'|| COUNTRY_CODE=='it'|| COUNTRY_CODE=='de'
									|| COUNTRY_CODE=='at'|| COUNTRY_CODE=='cz'|| COUNTRY_CODE=='fr'|| COUNTRY_CODE=='hu'|| COUNTRY_CODE=='nl'|| COUNTRY_CODE=='cac_en'|| COUNTRY_CODE=='se'){
									noticeIconClass = 'no-icon'
								}
								/*LGEUK-462, LGEGMC-2772, LGEGMC-2825 E*/
								if(addToCart.successHTML==null && successMsg && addToCart.successBtnAlert && successMsg && addToCart.successBtnClose) {
									// LGEGMC-1278, LGEGMC-1518
									if(ePrivacyCookies.bannerType=='N'||ePrivacyCookies.bannerType=='I'){
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div></div></div></div>'; /* LGEGMC-243 20200521 modify, LGEGMC-2485 */
									}else{
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div><div class="simple-content-box"><div class="notice '+noticeIconClass+'" style="display:inline-block;">'+addToCart.cookieNotice+'</div></div></div></div></div>'; // LGEGMC-2485
									}
									// LGEGMC-1278, LGEGMC-1518 End
								}

								if(addToCart.successHTML!=null) {
									if($('#addToCartSuccess').length>0) $('#addToCartSuccess').remove();
									$('body').find('.iw_viewport-wrapper').first().append(addToCart.successHTML); //LGEGMC-2772
									$('#addToCartSuccess').modal();
									$('#addToCartSuccess').find('button[data-dismiss=modal]').on('click.close', function() {
										addToCart.currentFocus();
									});
								}	
							} else if(data.data.addExtendedWarrantyToCart && data.data.addExtendedWarrantyToCart.cart && data.data.addExtendedWarrantyToCart.cart.items){
								/* PJTEXTENDEDWTY-4 Start */
								var cnt = 0;
								if(len>0) {
									for(var j=0;j<len;j++) {
										cnt = cnt + parseInt(items[j].qty);
									}
								}
								addToCart.setCartNum(cnt);
								
								var	successMsg = addToCart.successMsg;
								/*LGEUK-462, LGEGMC-2825 S*/
								var noticeIconClass ='';
								if(COUNTRY_CODE=='uk'
									|| COUNTRY_CODE=='at'|| COUNTRY_CODE=='cz'|| COUNTRY_CODE=='fr'|| COUNTRY_CODE=='hu'|| COUNTRY_CODE=='nl'|| COUNTRY_CODE=='cac_en'|| COUNTRY_CODE=='se'){
									noticeIconClass = 'no-icon'
								}
								/*LGEUK-462, LGEGMC-2825 E*/
								if(addToCart.successHTML==null && successMsg && addToCart.successBtnAlert && successMsg && addToCart.successBtnClose) {
									// LGEGMC-1278, LGEGMC-1518
									if(ePrivacyCookies.bannerType=='N'||ePrivacyCookies.bannerType=='I'){
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div></div></div></div>'; /* LGEGMC-243 20200521 modify */
									}else{
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div><div class="simple-content-box"><div class="notice '+noticeIconClass+'" style="display:inline-block;">'+addToCart.cookieNotice+'</div></div></div></div></div>';
									}
									// LGEGMC-1278, LGEGMC-1518 End
								}

								if(addToCart.successHTML!=null) {
									if($('#addToCartSuccess').length>0) $('#addToCartSuccess').remove();
									/* LGCOMMS-146 start */
									if($('body').find('.support-wrap').length>0){
										$('body').find('.support-wrap').first().append(addToCart.successHTML);
										$('#modal_ew_confirm').modal('hide');
									}else{
										$('body').append(addToCart.successHTML);
									}
									/* LGCOMMS-146 end */
									$('#addToCartSuccess').modal();
									$('#addToCartSuccess').find('button[data-dismiss=modal]').on('click.close', function() {
										addToCart.currentFocus();
										
										var wtyAddToCartCloseUrl = $('.extended-warranty').find('#wtyAddToCartCloseUrl').val();
										if(!!wtyAddToCartCloseUrl){
											window.location.href = wtyAddToCartCloseUrl;
										}
										
									});
								}
								/* PJTEXTENDEDWTY-4 End */
							//PJTPPDPAOB-1 START
							}else if(data.data.addAddonBundlePackageToCart && data.data.addAddonBundlePackageToCart.cart && data.data.addAddonBundlePackageToCart.cart.items){
								
								var cnt = 0;
								if(len>0) {
									for(var j=0;j<len;j++) {
										cnt = cnt + parseInt(items[j].qty);
									}
								}
								addToCart.setCartNum(cnt);
								
								//PJTOBSB2E-3 add
								var atcBtn = $(_obj).closest('.button').find('.pre-order');
								var successMsg = '';
								if(atcBtn.length > 0){
									successMsg = addToCart.preOrderSuccessMsg;
								}else{
									successMsg = addToCart.successMsg;
								}
								//PJTOBSB2E-3 add
								/*LGEUK-462, LGEGMC-2772, LGEGMC-2825 S*/
								var noticeIconClass ='';
								if(COUNTRY_CODE=='uk' || COUNTRY_CODE=='pl'|| COUNTRY_CODE=='it'|| COUNTRY_CODE=='de'
									|| COUNTRY_CODE=='at'|| COUNTRY_CODE=='cz'|| COUNTRY_CODE=='fr'|| COUNTRY_CODE=='hu'|| COUNTRY_CODE=='nl'|| COUNTRY_CODE=='cac_en'|| COUNTRY_CODE=='se'){
									noticeIconClass = 'no-icon'
								}
								/*LGEUK-462, LGEGMC-2772, LGEGMC-2825 E*/
								if(addToCart.successHTML==null && successMsg && addToCart.successBtnAlert && successMsg && addToCart.successBtnClose) {
									// LGEGMC-1278, LGEGMC-1518
									if(ePrivacyCookies.bannerType=='N'||ePrivacyCookies.bannerType=='I'){
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div></div></div></div>'; /* LGEGMC-243 20200521 modify, LGEGMC-2485 */
									}else{
										addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary">'+addToCart.successBtnAlert+'</a></div><div class="simple-content-box"><div class="notice '+noticeIconClass+'" style="display:inline-block;">'+addToCart.cookieNotice+'</div></div></div></div></div>'; // LGEGMC-2485
									}
									// LGEGMC-1278, LGEGMC-1518 End
								}

								if(addToCart.successHTML!=null) {
									if($('#addToCartSuccess').length>0) $('#addToCartSuccess').remove();
									$('body').find('.iw_viewport-wrapper').first().append(addToCart.successHTML); //LGEGMC-2772
									$('#addToCartSuccess').modal();
									$('#addToCartSuccess').find('button[data-dismiss=modal]').on('click.close', function() {
										addToCart.currentFocus();
									});
								}
								
							//PJTPPDPAOB-1 end
							} else {
								// redirect 
								location.href=data.data.addModelToCart.redirectUrl;
							}
						}
						$('body').trigger('ajaxLoadEnd');
					},
					error: function(request, status, error) {
						//console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
						$('body').trigger('ajaxLoadEnd');
					},
					// OBSLGEIN-564 Start
					complete: function(){
						if(typeof $('#exchangeProgramUseFlag') != "undefined") {
							if(null != $('#exchangeProgramUseFlag').val() && "Y" == $('#exchangeProgramUseFlag').val()) {
								var quoteId = "";
								
								if(typeof $('#cartQuoteId') != "undefined") {
									if('' != $('#cartQuoteId').val()) quoteId = $('#cartQuoteId').val();
								}  
								
								var exchangeProgramPrice = '';
								exchangeProgramPrice = $('.exchange-detail').attr('data-bonusprice');
								var exchangeProgramCategoryId = $('.exchange-detail').attr('data-categoryid');
								var exchangeProgramTypeId = $('.exchange-detail').attr('data-typeid');
								var exchangeProgramBrandId = $('.exchange-detail').attr('data-brandid');
								var exchangeProgramCondition = $('.exchange-detail').attr('data-condition');
								var exchangeProgramModelName = $('.exchange-detail').attr('data-exmodelname');
								if(addToCart.store == 'in' && exchangeProgramPrice != '' && '' != quoteId){
									$.ajax({
										url: url,
										headers: headers,
										type: 'post',
										data: JSON.stringify({
											'query': '{ add_exchange_information_to_quote(quote_id:'+quoteId+', product_type_id:'+exchangeProgramCategoryId+', model_type_id:'+exchangeProgramTypeId+', brand_id:'+exchangeProgramBrandId+', product_condition:"'+exchangeProgramCondition+'", bonus:"'+exchangeProgramPrice+'", exchange_product_name:"'+exchangeProgramModelName+'", estimated_delivery_date:"" ) {Status,Message,Detail{quote_id}} }',
											'variables': null,
											'operationName': null
										}),
										dataType: 'json',
										success: function(data) {
											if(data.errors) {
												var errorMsg = '';
												for(var i=0;i<data.errors.length;i++) {
													errorMsg = errorMsg + data.errors[i].message;
												}
											}else {
												var exchangeInfExistStatus = true;
												var exchangeInfExistMsg = "";
												
												if(data.data && data.data.add_exchange_information_to_quote){
													if(data.data.add_exchange_information_to_quote.Status == false){
														exchangeInfExistStatus = false;
														exchangeInfExistMsg = data.data.add_exchange_information_to_quote.Message;
													}
												}
												if(exchangeInfExistStatus == false && exchangeInfExistMsg == "Smart exchange information existed in this quote."){
													$('#model-exist-alert').modal();
												}else {
													console.log(data);
												}
											}
										},
										error: function(request, status, error) {
											$('body').trigger('ajaxLoadEnd');
										}
									})
								} else {
									console.log('quoteId='+quoteId+', fail api add_exchange_information_to_quote');
								}
							}
						}
					}
					// OBSLGEIN-564 End
				});
			
		},
		getGNBCart: function() {
			// cart check
			var url = addToCart.$nav.data('cart-check');
			if (url && addToCart.cartID) {
				var authToken = OBS_Y.getToken();
				var headers = {};
				if(authToken != '') {
					headers.Authorization = "Bearer " + authToken;
				}
				headers.Store = addToCart.$nav.data('store');
				headers["Content-type"] = "application/json";
				$.ajax({
					url: url,
					headers: headers,
					type: 'post',
					data: JSON.stringify({'query': '{cart(cart_id: "'+addToCart.cartID+'") { items { product {  sku name } qty } }}', 'variables':null, 'operationName':null}),
					dataType: 'json',
					success: function(data) {
						if(data.errors) {
							/* LGCOMSM-34, LGESA-330 createEmptyCart 로직삭제 */
						} else {
							var len = data.data.cart.items.length;
							var cnt = 0;
							if(len>0) {
								for(var i=0;i<len;i++) {
									cnt = cnt + parseInt(data.data.cart.items[i].qty);
								}
								addToCart.setCartNum(cnt);
							}
						}
					},
					error: function(request, status, error) {
						//console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
					}
				});
			}
		},
		// LGEIN-71, LGEIN-125, LGEVN-80, LGEGMC-1914
		checkSale: function(sku, qty, pincode) {
			
			var url = addToCart.url,
				countryCode = $('html').data('countrycode').toUpperCase(),
				pinValidHtml = "<span class='check-fail'>" + $component.find("#pinValid").val() + "</span>",
				data = null;
			
			var headers = {};
			headers.Store = addToCart.$nav.data('store');
			headers["Content-type"] = "application/json";
			
			// LGEAU-249, LGEMS-189, LGEBR-1361, LGEIN-1070 START
			if(countryCode == 'AU'){
				/*LGCOMAU-104 Start*/
				if($(".GPC0009").find(".add-bundle-area").length>0 && $(".GPC0009").find(".add-bundle-area li").length>0){
					$(".GPC0009 .add-bundle-area .bundle-list .bundle-select a").removeClass("act available disabled");
					$(".GPC0009 .add-bundle-area .bundle-list .bundle-select a").find(".bundle-unable").addClass("d-none");
					const modelSplit = sku.split(',');
					 let addOnBundelParam = "";
					 let mainBundelParam = modelSplit[0];
					 for (var i=1; i<modelSplit.length; i++) {
	                    addOnBundelParam += '{ sku: "' + modelSplit[i] + '" ,qty: 1},';
	                 }
	                 addOnBundelParam = addOnBundelParam.substring(0, addOnBundelParam.length - 1);
					 data = JSON.stringify({"query": 'mutation {checkSaleAddonBundle(pincode: "'+pincode+'",data:{main_item: "' + mainBundelParam + '", qty: 1,package_items:['+addOnBundelParam+']}) {pincode, main_item {message, deliveryLeadTime } package_items {sku, status}}}'});
				}else{
					data = JSON.stringify({"query": '{AuCheckSale(sku: "'+sku+'", qty: '+qty+', postcode: "'+pincode+'") {message, deliveryLeadTime}}',
	                  "variables":null});
				}
				/*LGCOMAU-104 Start*/
			} else if (countryCode == 'MX'){
				data = JSON.stringify({"query": '{MxCheckSale(sku: "'+sku+'", qty: '+qty+', pincode: "'+pincode+'") {message, mxdelivery_check}}',
					"variables":null});
			} else if (countryCode == 'BR') {
				data = JSON.stringify({"query": '{BrCheckSale(sku: "'+sku+'", qty: '+qty+', postcode: "'+pincode+'") {message, deliveryLeadTime}}',
					"variables":null});
			} else if (countryCode == 'IN'){
				data = JSON.stringify({"query": '{checkEwPincode(pincode: "'+pincode+'") {isEwPincodeAvailable}checkSale(sku: "'+sku+'", qty: '+qty+', pincode: '+pincode+') {message, deliveryLeadTime}}',
	                  "variables":null});
			} else {
				data = JSON.stringify({"query": '{checkSale(sku: "'+sku+'", qty: '+qty+', pincode: '+pincode+') {message, deliveryLeadTime}}',
	                  "variables":null});
			}
			// LGEAU-249, LGEMS-189, LGEBR-1361, LGEIN-1070 END
			
			if($component.find(".check-fail").length>0 || $component.find(".check-good").length>0) $component.find(".check-fail").remove(); $component.find(".check-good").remove();
			$.ajax({
				url: url,
				headers: headers,
				type: "post",
				data: data,
				dataType: 'json',
				success: function(data) {
					var items = null;
					if(data.errors){
						/*LGCOMAU-104 Start*/
						if(countryCode == 'AU'){
							if($(".GPC0009").find(".add-bundle-area").length>0 && $(".GPC0009").find(".add-bundle-area li").length>0){
								pinValidHtml = "<span class='check-fail'>" + data.errors[0].message + "</span>";
							}
						}
						/*LGCOMAU-104 End*/
						
						if($(".GPC0009").hasClass("before")){
							$component.find(".delivery-list .error-msg").show().append($(pinValidHtml));
						}else if($(".GPC0009").hasClass("improve")){
							$component.find(".checkStock-list .error-msg").show().append($(pinValidHtml));
						}

						// PJTOBS-31 Start
						// Package
						if($('.GPC0010').length>0) {
							$('.GPC0010 .products-list-wrap input[type="checkbox"]').attr('disabled', true).prop('checked', false);
							$('.GPC0010 .products-list-wrap input[type="radio"]').attr('disabled', true).prop('checked', false);
							packageComponent.sumPrice();
						}
						// PJTOBS-31 End
						//LGCOMMON-2165 Start
						if(countryCode == 'IN'){
							if(salesForceRunV2){
								items = {};
								items.pinCode = pincode;
								items.deliveryLeadTime = null;
								items.message = $component.find("#pinValid").val();
								salesForce.productPageSendEvent(items, 'pdp', salesForce.eventName.stockCheck);
							}
						}
						//LGCOMMON-2165 End
						
					} else {
						
						// LGEAU-249, LGEMS-189, LGEBR-1361 START
						/*LGCOMAU-104 Start*/
						var mainModelFlag = false;
						if(countryCode == 'AU'){
							if($(".GPC0009").find(".add-bundle-area").length>0 && $(".GPC0009").find(".add-bundle-area li").length>0){
								items = data.data.checkSaleAddonBundle;
								if(!!items.main_item){
									if(items.main_item.deliveryLeadTime != null && items.main_item.deliveryLeadTime != 0){
										mainModelFlag = true;
									}else{
										$(".GPC0009").find(".add-bundle-area").data("mainOutMsg",items.main_item.message);
									}
									
									$(".GPC0009").find(".add-bundle-area").data('main-model-flag',mainModelFlag).attr('data-main-model-flag',mainModelFlag)
								}
							}else{
								items = data.data.AuCheckSale;
							}
						} else if (countryCode == 'MX') {
							items = data.data.MxCheckSale;
						} else if (countryCode == 'BR') {
							items = data.data.BrCheckSale;
						} else {
							items = data.data.checkSale;
						}
						// LGEAU-249, LGEMS-189, LGEBR-1361 END
						/*LGCOMAU-104 End*/
						// LGEIN-1070 Start
						var pincodeGetStockAlertUseFlag = $('.pincode-get-stock-alert-use-flag').val();
						$('.getStockAlert-area').removeClass('act');
						var pincodeAvailable = null;
						if(countryCode == 'IN'){
							pincodeAvailable = data.data.checkEwPincode.isEwPincodeAvailable;

							//LGCOMMON-2165 Start
							if(salesForceRunV2){
								items.pinCode = pincode;
								salesForce.productPageSendEvent(items, 'pdp', salesForce.eventName.stockCheck);
							}
							//LGCOMMON-2165 End
						}
						
						// LGEIN-1070 End
						// LGEMS-189 START
						/*LGCOMAU-104 Start*/
						if((items.deliveryLeadTime != '' && items.deliveryLeadTime != null && items.deliveryLeadTime != "undefined" && typeof items.deliveryLeadTime === "number") 
							|| (items.mxdelivery_check != "undefined" && items.mxdelivery_check != '' && items.mxdelivery_check != null && items.mxdelivery_check == "1")
							|| (countryCode == 'BR' && items.deliveryLeadTime != "undefined" && items.deliveryLeadTime != '' && items.deliveryLeadTime != null && typeof items.deliveryLeadTime === "number" && items.deliveryLeadTime > 0)
							|| (data.data.checkSaleAddonBundle != '' && data.data.checkSaleAddonBundle != "undefined" && mainModelFlag)) {
							
							if (countryCode != 'MX') {
								var pinDate = new Date();
								pinDate.setDate(pinDate.getDate()+items.deliveryLeadTime);
							
								var dateplus  = pinDate.getDate(),
								monthNames = ["January", "February", "March", "April", "May", "June",
											  "July", "August", "September", "October",  "November", "December"],
								month = monthNames[pinDate.getMonth()];
							}  
							/*LGCOMBR-203 Start*/
							const bannerzip = $component.find(".check-pincode").data("bannerZipCode");
							var pinSelectBox = $component.find("#pinCodeList").val(),
								pinOK = $component.find("#pinOK").val(),
								pinSuccessMsg = $component.find("#pinSuccessMsg").val(),
								pinChangeMsg = $component.find("#pinChangeMsg").val(),
								isPreOrderProduct = $component.find('.pre-order-day'),
								pinOkHtml = "<p class='pincode-ok'>" + pinOK +  "</p>",
								bannerHtml ="",
								pinSuccesHtml = "";
							if(!!bannerzip){
								let bannerZipMsg = $component.find(".check-pincode").data("bannerZipMsg");
								let bannerZipImg = $component.find(".check-pincode").data("bannerZipImg");
								let bannerZipAlt = $component.find(".check-pincode").data("bannerZipAlt");
							bannerHtml ="<div class=\"special-promotion d-none\"><p class=\"text-promotion\">"+bannerZipMsg+"</p>"
								+"<div class='p-banner'>"
								+"<img class=\"\" src=\""+bannerZipImg+"\" alt=\""+bannerZipAlt+"\" />"
								+"</div>"
								+"</div>";
							}
							
							if (countryCode == 'MX') {
								pinSuccessMsg = items.message != '' ? items.message : $component.find("#pinSuccessMsg").val();

								pinSuccesHtml = "<div class='check-good' data-pin-code="+pincode+">" + pinOkHtml		/* LGEGMCOBS-74 & LGEMS-491 add pincode */
								+ "<p>" + pinSuccessMsg.replace(/\*noMessage\*/g, '') + "</p><a href='#' role='button'><span>" + pinChangeMsg
								+ "</span></a></div>";
							}else if(countryCode == 'IN' && isPreOrderProduct.length > 0){
								//LGEIN-586, LGEIN-740 START
								pinSuccesHtml = "<div class='check-good' data-pin-code="+pincode+">" + pinOkHtml
								+ "<a href='#' role='button'><span>" + pinChangeMsg
								+ "</span></a></div>";
								//LGEIN-586
							} else if(countryCode == 'BR'&& !!bannerzip){
								pinSuccesHtml = "<div class='check-good' data-pin-code="+pincode+">" + pinOkHtml
									+ bannerHtml
									+ "<p>" + pinSuccessMsg.replace(/\*dateplus\*/g, dateplus).replace(/\*month\*/g, month).replace(/\*noMessage\*/g, '') + "</p><a href='#' role='button'><span>" + pinChangeMsg
									+ "</span></a></div>";
							} else{
								pinSuccesHtml = "<div class='check-good' data-pin-code="+pincode+">" + pinOkHtml
									+ "<p>" + pinSuccessMsg.replace(/\*dateplus\*/g, dateplus).replace(/\*month\*/g, month).replace(/\*noMessage\*/g, '') + "</p><a href='#' role='button'><span>" + pinChangeMsg
									+ "</span></a></div>";
							}
							// LGEIN-740 END
							// LGEMS-189 END
							/*LGCOMBR-203 End*/
							if($(".GPC0009").hasClass("before")){
								$component.find(".delivery-list .error-msg").show().append($(pinSuccesHtml));
							}else if($(".GPC0009").hasClass("improve")){
								$component.find(".checkStock-list .error-msg").show().append($(pinSuccesHtml));
							}
							/*LGCOMBR-203 Start*/	
							if(!!bannerzip){
								const bannerzipRange = bannerzip.split(':');
								let bannerzipFlag = false;
								let pincodeNum = parseInt(pincode.replace(/[\-]/gi,'').replace(/(^0+)/,''));
								$.ajax({
									url: bannerzip,
									type: "post",
									data: "pincodeNum="+pincodeNum,
									dataType: 'json',
									success: function(data) {
										 if(data.data[0].bannerzipFlag){
											 $component.find(".special-promotion").removeClass("d-none")
										 }else{
											 $component.find(".special-promotion").remove()
										 }
									}
								});
							}/*LGCOMBR-203 End*/
							if($(".GPC0009").hasClass("before")){
								if($component.find(".delivery-list .option-list").length > 0){	
									$component.find(".option-list").hide();
								}else{
									$component.find(".check-pincode").hide();
								}
							}else if($(".GPC0009").hasClass("improve")){
								if($component.find(".checkStock-list .option-list").length > 0){	
									$component.find(".option-list").hide();
								}else{
									$component.find(".check-pincode").hide();
								}
							}
							
							$component.find(".chkStBox a.btn-sm").hide();
								
							/* LGEGMC-516 20200806 add */
							$component.find(".add-to-cart").attr({ "data-link-area": "pdp_main-btn", "data-link-name":"add_to_cart"});
							$('.GPC0011').find(".button .add-to-cart").attr({ "data-link-area": "pdp_tab_anchor-tab", "data-link-name":"add_to_cart"});
							/* //LGEGMC-516 20200806 add */
							/*LGCOMAU-104 Start*/
							if(!!items.package_items){
								if(items.package_items.length > 0){
									for(var i=0;i<items.package_items.length;i++) {
										$(".GPC0009 .add-bundle-area .bundle-list .bundle-select").each(function(){
											var returnSku = items.package_items[i].sku;
											returnSku = returnSku.replaceAll('AU.','');
											if($(this).data("modelid")==returnSku){
												if(items.package_items[i].status == 'IN_STOCK'){
													$(this).find("a").addClass("available");
													$(this).find(".bundle-unable").addClass("d-none");
												}
											}
										})
									}
								}
								$(".GPC0009 .add-bundle-area .bundle-list .bundle-select a").not(".available").each(function(){
									$(this).addClass("disabled");
									$(this).find(".bundle-unable").removeClass("d-none");
								});
							}
							/*LGCOMAU-104 End*/
							$component.find(".check-good a").on("click",function(e){
								e.preventDefault();
								/*LGCOMAU-104 Start*/
								$(".GPC0009 .add-bundle-area .bundle-list .bundle-select a").removeClass("act available disabled");
								$(".GPC0009 .add-bundle-area .bundle-list .bundle-select a").find(".bundle-unable").addClass("d-none");
								/*LGCOMAU-104 End*/
								//LGEIN-125
								$component.find(".check-good").remove();
								
								if($(".GPC0009").hasClass("before")){
									$component.find(".delivery-list .error-msg").hide();
								}else if($(".GPC0009").hasClass("improve")){
									$component.find(".checkStock-list .error-msg").hide();
								}
								
								if($(".GPC0009").hasClass("before")){
									if($component.find(".delivery-list .option-list").length > 0){
										$component.find(".option-list").show(); $(pinSelectBox).trigger('chosen:activate'); $component.find(".check-pincode").focus();
										$component.find(".pdp-info.desktop-info .chosen-initialized").hide();
									} else {
										$component.find(".check-pincode").val('').show().focus();
									}
								}else if($(".GPC0009").hasClass("improve")){
									if($component.find(".checkStock-list .option-list").length > 0){
										$component.find(".option-list").show(); $(pinSelectBox).trigger('chosen:activate'); $component.find(".check-pincode").focus();
										$component.find(".pdp-info.desktop-info .chosen-initialized").hide();
									} else {
										$component.find(".check-pincode").val('').show().focus();
									}
								}
								
								$component.find(".chkStBox a.btn-sm").show();
								
								/* LGEGMC-516 20200806 add */
								$component.find(".add-to-cart").removeAttr("data-link-area");
								$component.find(".add-to-cart").removeAttr("data-link-name");
								$('.GPC0011').find(".button .add-to-cart").removeAttr("data-link-area");
								$('.GPC0011').find(".button .add-to-cart").removeAttr("data-link-name");
								/* //LGEGMC-516 20200806 add */
								
							});

							// PJTOBS-31 Start
							// Package
							if($('.GPC0010').length>0) {
								$('.GPC0010 .products-list-wrap input[type="checkbox"]').removeAttr('disabled');
								$('.GPC0010 .products-list-wrap input[type="radio"]').removeAttr('disabled');
							}
							// PJTOBS-31 End
						} else if(items.message == "Error"){
							if($(".GPC0009").hasClass("before")){
								$component.find(".delivery-list .error-msg").show().append($(pinValidHtml));
							}else if($(".GPC0009").hasClass("improve")){
								$component.find(".checkStock-list .error-msg").show().append($(pinValidHtml));
							}

							// PJTOBS-31 Start
							// Package
							if($('.GPC0010').length>0) {
								$('.GPC0010 .products-list-wrap input[type="checkbox"]').attr('disabled', true).prop('checked', false);
								$('.GPC0010 .products-list-wrap input[type="radio"]').attr('disabled', true).prop('checked', false);
								packageComponent.sumPrice();
							}
							// PJTOBS-31 End							
						} else {
							if(!mainModelFlag&&countryCode == 'AU'&&$(".GPC0009").find(".add-bundle-area").length>0 && $(".GPC0009").find(".add-bundle-area li").length>0 && !!!items.message){
								items.message =$(".GPC0009").find(".add-bundle-area").data("mainOutMsg");
							}
							
							var pinErrorHtml = "<span class='check-fail'>" + items.message + "</span>"; 
							
							// LGEBR-1361  Start
							if (countryCode == 'BR') {
								var brFailMsg = items.message;
								var pinApiMsg1 = $component.find("#pinApiMsg1").val();
								var pinApiMsg2 = $component.find("#pinApiMsg2").val();
								
								if(pinApiMsg1 == items.message){
									brFailMsg = $component.find("#pinFailMsg1").val();
								} else if(pinApiMsg2 == items.message){
									brFailMsg = $component.find("#pinFailMsg2").val();
								}
								
								pinErrorHtml = "<span class='check-fail'>" + brFailMsg + "</span>";
							}
							// LGEBR-1361  End
							
							if($(".GPC0009").hasClass("before")){
								$component.find(".delivery-list .error-msg").show().append($(pinErrorHtml));
							}else if($(".GPC0009").hasClass("improve")){
								$component.find(".checkStock-list .error-msg").show().append($(pinErrorHtml));
							}

							// PJTOBS-31 Start
							// Package
							if($('.GPC0010').length>0) {
								$('.GPC0010 .products-list-wrap input[type="checkbox"]').attr('disabled', true).prop('checked', false);
								$('.GPC0010 .products-list-wrap input[type="radio"]').attr('disabled', true).prop('checked', false);
								packageComponent.sumPrice();
							}
							// PJTOBS-31 End
							
							// LGEIN-1070 Start
							if(pincodeGetStockAlertUseFlag == 'Y' && items.deliveryLeadTime == 0 && pincodeAvailable == true){
								$('.getStockAlert-area').addClass('act');
							}
							// LGEIN-1070 End
						}
						// LGCOMIN-3 Start
						if(countryCode == 'IN'){							
							dataLayer.push({
								'event'		: 'pincode_input_by_visitor',
								'pincode'	: pincode
							});

							dataLayer.push({
								'event'		: 'checksale_message',
								'message'	: items.message
							});

							dataLayer.push({
								'event'				: 'check_pincode',
								'pincodeAvailable'	: pincodeAvailable
							});
						}
						// LGCOMIN-3 End						
					}
					$('body').trigger('ajaxLoadEnd');
				},
				error: function(request, status, error) {
					console.log("checkSale code:"+request.status+"\n"+"message:"+request.responseText+"\n"+"error:"+error);
					if($(".GPC0009").hasClass("before")){
						$component.find(".delivery-list .error-msg").show().append($(pinValidHtml));
					}else if($(".GPC0009").hasClass("improve")){
						$component.find(".checkStock-list .error-msg").show().append($(pinValidHtml));
					}
					$('body').trigger('ajaxLoadEnd');
					/*LGCOMIN-236 s*/
					if(countryCode == 'IN'){
						//LGCOMMON-2165 Start
						if(salesForceRunV2){
							items = {};
							items.pinCode = pincode;
							items.deliveryLeadTime = null;
							items.message = $component.find("#pinValid").val();
							salesForce.productPageSendEvent(items, 'pdp', salesForce.eventName.stockCheck);
						}
						//LGCOMMON-2165 End
						
						let responsemessage = JSON.parse(request.responseText);
							dataLayer.push({
								'event'		: 'pincode_input_by_visitor',
								'pincode'	: pincode
							});
	
							dataLayer.push({
								'event'		: 'checksale_message',
								'message'	: responsemessage.errors[0].message
							});
	
							dataLayer.push({
								'event'				: 'check_pincode',
								'pincodeAvailable'	: false
							});
					}
					/*LGCOMIN-236 e*/
				}
			});
		},
        /* LGEGMC-1914 START */
		comunaChange: function(e) {
			
			var $comuna = $(e.currentTarget),
				param = $comuna.serialize(),
				url = $comuna.data('url');
			
			$('.checkAvailable a.btn-sm').addClass("disabled");
			if(COUNTRY_CODE != 'cl' && COUNTRY_CODE != 'pe') //LGECL-248, OBSLGEPE-142
			$component.find('.add-to-cart').addClass("disabled");
			
			if($component.find(".check-fail").length>0 || $component.find(".check-good").length>0) $component.find(".check-fail").remove(); $component.find(".check-good").remove();
			$.ajax({
				type: 'post',
				url: url,
				dataType: 'json',
				data: param,
				success: function(data) {
					if (data.result) {
						var $target = isMobile || $(window).width() < 767 ? $('.check-comuna') : $('#comuna'),
							comunaList = data.comunaList,
							comunaInfoList = data.comunaInfoList,
							option = '<option value="" selected disabled>' + $target.data('placeholder') + '</option>';
						//OBSLGEPE-142 START
						if(COUNTRY_CODE == 'cl'){
							for (var i  in comunaList) {
								option += '<option value="' + comunaList[i].value + '">' + comunaList[i].code + '</option>';
							}							
						}else {
							for (var i  in comunaInfoList) {
								option += '<option value="' + comunaInfoList[i].value + '">' + comunaInfoList[i].code + '</option>';
							}		
						}
						
						$target.html(option);
						$target.prop('disabled', false);
						$target.trigger('chosen:updated');
						
						$target.on('change', function(e){
							$('.checkAvailable a.btn-sm').removeClass("disabled");
							if(COUNTRY_CODE != 'cl' && COUNTRY_CODE != 'pe') //LGECL-248, OBSLGEPE-142
							$component.find('.add-to-cart').addClass("disabled");
							if($component.find(".check-fail").length>0 || $component.find(".check-good").length>0) $component.find(".check-fail").remove(); $component.find(".check-good").remove();
						});
						
					}
				},
				error: function(request, status, error) {
					console.log("comunaChange code:"+request.status+"\n"+"message:"+request.responseText+"\n"+"error:"+error);
				}
			});
		},
		checkComuna: function(comunaId) {
			
			var $comuna = $('#comuna'),
				url = $comuna.data('comuna-url'),
				countryCode = $('html').data('countrycode'),
				validHtml = "<span class='check-fail'>" + $component.find("#pinValid").val() + "</span>";
			
			var headers = {};
			headers.Store = addToCart.$nav.data('store');
			headers["Content-type"] = "application/json";
			
			if($component.find(".check-fail").length>0 || $component.find(".check-good").length>0) $component.find(".check-fail").remove(); $component.find(".check-good").remove();
			$.ajax({
				type: 'post',
				url: url+"/"+comunaId,
				dataType: 'json',
				success: function(data) {
					
					var availableShippingMsg = data.available_shipping_message,
						deliveryDayMsg = data.delivery_day_message,
						ubigeoErrorMsg = data.error_message, //OBSLGEPE-142
						checkCart = data.add_to_cart,
						html = "";
					
					if(checkCart == 1){
						
						html = "<div class='check-good'>" +"<p class='available-ok'>"+ availableShippingMsg +"<br>"+ deliveryDayMsg +"</p>"+"</div>";
						$('.error-msg').append(html).show();
						
						$component.find('.add-to-cart').removeClass("disabled");
						
					} else if(checkCart == 0) {
						
						html = "<span class='check-fail'>" + availableShippingMsg + "</span>";
						$('.error-msg').append(html).show();
						
					} else if($comuna != comunaId) { //OBSLGEPE-142
						
						html = "<span class='check-fail'>" + ubigeoErrorMsg + "</span>";
						$('.error-msg').append(html).show();
						
					} else {
						
						$('.error-msg').append(validHtml).show();
						
					}
					$('body').trigger('ajaxLoadEnd');
				},
				error: function(request, status, error) {
					console.log("checkComuna code:"+request.status+"\n"+"message:"+request.responseText+"\n"+"error:"+error);
					
					$('.error-msg').append(validHtml).show();
					if(COUNTRY_CODE != 'cl' && COUNTRY_CODE != 'pe') //LGECL-248, OBSLGEPE-142
					$component.find('.add-to-cart').addClass("disabled");
					
					$('body').trigger('ajaxLoadEnd');
					
				}
			});
			
		}
		/* LGEGMC-1914 END */
	};

	var OBS_S = {
		addProductToCart: function(model, qty) {
			if(!model) return false;
			if(!$('.navigation').data('cart-add') || $('.navigation').data('cart-add') == '') return false;

			var authToken = OBS_Y.getToken();
			//var url = addToCart.url;

			// if(model.indexOf('|') != -1)  {
			// 	// for multi (GPC0010)
				
			// 	var modelList = model.split('|');
			// 	var len = modelList.length;
			// 	for(var i=0;i<len;i++) {
			// 	}
				

			// } else {
				// single

				//var form = new FormData();
				//form.append("sku", model);

				$.ajax({
					url: $('.navigation').data('cart-add'),
					type: 'post',
					//data: JSON.stringify({sku: model}),
					//data: form,
					data: {sku: model},
					xhrFields: {
						withCredentials: true
					},
					dataType: 'json',
					success: function(data) {

						if(data.result != true) {
							var errorMsg = data.message;

							//console.log(errorMsg);
							if($('#addToCartError').length>0) $('#addToCartError').remove();
							
							var html = '<div class="modal modal-simple fade" id="addToCartError" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Fail to add Cart" aria-describedby="addToCartFailDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartFailDialog">'+errorMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" data-dismiss="modal">'+addToCart.successBtnClose+'</button></div></div></div></div>'; /* LGEGMC-243 20200521 modify, LGEGMC-2485 */

							$('body').append(html);
							$('#addToCartError').modal();
							$('#addToCartError').find('button[data-dismiss=modal]').on('click.close', function() {
								addToCart.currentFocus();
							});

						} else {
							var successMsg = addToCart.successMsg;
							if(data.message && data.message!='' && data.message!=null) {
								successMsg = data.message;
							}

							if(typeof OBS.addCookieValue=='function') OBS.addCookieValue('CART', model);
							// GNB cart number +
							var cnt = data.totalQty;
							addToCart.setCartNum(cnt);
							/*LGEUK-462, LGEGMC-2825 S*/
							var noticeIconClass ='';
							
							if(COUNTRY_CODE=='uk'
								|| COUNTRY_CODE=='at'|| COUNTRY_CODE=='cz'|| COUNTRY_CODE=='fr'|| COUNTRY_CODE=='hu'|| COUNTRY_CODE=='nl'|| COUNTRY_CODE=='cac_en'|| COUNTRY_CODE=='se'){
								noticeIconClass = 'no-icon'
							}
							/*LGEUK-462, LGEGMC-2825 E*/
							if(addToCart.successHTML==null && successMsg && addToCart.successBtnAlert && successMsg && addToCart.successBtnClose) {
								if(ePrivacyCookies.bannerType=='N'||ePrivacyCookies.bannerType=='I'){
									addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary btn-block">'+addToCart.successBtnAlert+'</a></div></div></div></div>'; /* LGEGMC-243 20200521 modify */
								}else{
									addToCart.successHTML = '<div class="modal modal-simple fade" id="addToCartSuccess" tabindex="-1" role="dialog" data-backdrop="false" aria-label="Add to Cart" aria-describedby="addToCartSuccessDialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><div class="simple-content-box"><div class="content-paragraph" id="addToCartSuccessDialog">'+successMsg+'</div></div></div><div class="modal-footer"><button type="button" class="btn btn-secondary btn-block" data-dismiss="modal">'+addToCart.successBtnClose+'</button><a href="'+xssfilter(addToCart.$nav.find('.cart a').attr('href'))+'" class="btn btn-primary btn-block">'+addToCart.successBtnAlert+'</a></div><div class="simple-content-box"><div class="notice '+noticeIconClass+'" style="display:inline-block;">'+addToCart.cookieNotice+'</div></div></div></div></div>'; /* LGEGMC-243 20200521 modify *//*LGEUK-462 20211111*/
								}
							}

							if(addToCart.successHTML!=null) {
								if($('#addToCartSuccess').length>0) $('#addToCartSuccess').remove();
								$('body').append(addToCart.successHTML);
								$('#addToCartSuccess').modal();
								$('#addToCartSuccess').find('button[data-dismiss=modal]').on('click.close', function() {
									addToCart.currentFocus();
								});
							}
						}
						$('body').trigger('ajaxLoadEnd');
					},
					error: function(request, status, error) {
						$('body').trigger('ajaxLoadEnd');
					}
				});
				

			// }
		},
		getGNBCart : function() {

			if(!$('.navigation').data('cart-check') || $('.navigation').data('cart-check') == '') return false;

			$.ajax({
				url: $('.navigation').data('cart-check'),
				type: 'post',
				xhrFields: {
					withCredentials: true
				},
				dataType: 'json',
				success: function(data) {

					if(data.totalQty) {
						// GNB cart number +
						var cnt = data.totalQty;
						addToCart.setCartNum(cnt);
					}

					$('body').trigger('ajaxLoadEnd');
				},
				error: function(request, status, error) {
					$('body').trigger('ajaxLoadEnd');
				}
			});
		}
	};

	// PJTOBS 20200702 Start
	reStockAlert = {
		callReStockAlert: function(url, dataLayer) {
			if(!url || !dataLayer) return false;
			$.ajax({
					type: 'post',
					url: url,
					dataType: 'html',
					data: xssfilter(dataLayer),
					success: function (html) {
						$('body').trigger('ajaxLoadEnd');
						$('#modal_re_stock_alert').remove();
						$('body').append(html);
						$('#modal_re_stock_alert').modal();
						reStockAlert.autoValidationForm();
						setTimeout(function() {
							$('#modal_re_stock_alert').css('display', 'none').css('display', 'block'); // fix ie bug
						}, 500);
					},
					error: function (request, status, error) {
						$('body').trigger('ajaxLoadEnd');
						console.log("status: " + status);
						console.log("error: " + error);
					}
				});
		},
		autoValidationForm: function() {
			$('#modal_re_stock_alert .re-stock-alert-form').on({
				'ajaxLoadBefore':function(e){
					e.stopPropagation();
					$(this).append('<div class="loading-circle"><div class="lds-dual-ring"></div></div>');
				},
				'ajaxLoadEnd':function(e){
					e.stopPropagation();
					$(this).find('.loading-circle').remove();
				}
			});
			$('#reStockAlertForm').on({
				submit: function(e){
					e.preventDefault();
					$('#modal_re_stock_alert .re-stock-alert-form').trigger('ajaxLoadBefore');
					var $this = $(this);
					if(!$this.checkValidation()){
						$('#modal_re_stock_alert .re-stock-alert-form').trigger('ajaxLoadEnd');
					}else {
						var url = $this.data('url');
						var param = $this.serialize();
						ajax.noCacheCall(url, param, 'json', function (data) {
							$('#modal_re_stock_alert .re-stock-alert-form').trigger('ajaxLoadEnd');
							// LGEAU-966 Start
							if (data.returnCode) {
								$('#modal_re_stock_alert .re-stock-alert-form').hide();
								var rCode = data.returnCode;
								var contactFlag = data.contactFlag;
								if(rCode=='00') { // 성공
									//PJTGADL-2
									dataLayer.push({
										'event'			: 'complete_stock_request_click',
										'superCategory'	: $('#reStockAlertForm').attr('data-super-category-name'),
										'category'		: $('#reStockAlertForm').attr('data-category-name'),
										'subcategory'	: $('#reStockAlertForm').attr('data-sub-category-name'),
										'modelYear'		: $('#reStockAlertForm').attr('data-model-year'),
										'modelName'		: $('#reStockAlertForm').attr('data-model-name'),
										'modelCode'		: $('#reStockAlertForm').attr('data-model-id'),
										'salesModelCode': $('#reStockAlertForm').attr('data-model-salesmodelcode'),
										'sku'			: $('#reStockAlertForm').attr('data-sku'),
										'suffix'        : $('#reStockAlertForm').attr('data-model-suffixcode'),
										'bu'            : $('#reStockAlertForm').attr('data-bu'),
										'price'         : $('#reStockAlertForm').attr('data-price'),
										'currencyCode'	: $('.currency-code').val(),
										'dimension185'  : $('.navigation').attr('data-obs-group'),
										'metric4'       : $('#reStockAlertForm').attr('data-msrp'),
									});
									console.log('complete_stock_request_click');
									//PJTGADL-2
									$('#modal_re_stock_alert .result-success').show();
								} else if(rCode=='01') { // 중복
									$('#modal_re_stock_alert .result-duplicate').show();
								} else if(rCode=='98') {
									$('#modal_re_stock_alert .re-stock-alert-form').show();
									var block = $("#txtBoxPostcode").parents('.field-block');
									if(block.find('.ajax-msg').length == 0){
										$("#txtBoxPostcode").parent().find('.error-msg').append('<span class="ajax-msg"></span>');
									}
									block.addClass('error').find('.ajax-msg').html(data.error).show().siblings().hide();
									// LGEAU-966 End
								} else { // 실패
									$('#modal_re_stock_alert .result-error').show();
								}
							} else {
								$('#modal_re_stock_alert .re-stock-alert-form').hide();
								$('#modal_re_stock_alert .result-error').show();
							}
						}, 'post', '#modal_re_stock_alert .re-stock-alert-form');
					}
				}
			});
			$('#reStockAlertSubmit').click(function(e) {
				e.preventDefault();
				$('#reStockAlertForm').trigger('submit');
			});
		},
		init: function() {
			$('body').off('click.restockalert').on('click.restockalert', '.re-stock-alert', function (e) {
				e.preventDefault();
				var url = $(this).data('url');
				//PJTGADL-2
				var dataLayer = {};
				dataLayer['subCategoryName'] = $(this).data('sub-category-name');
				dataLayer['categoryName'] = $(this).data('category-name');
				dataLayer['superCategoryName'] =  $(this).data('super-category-name');
				dataLayer['modelYear'] = $(this).data('model-year');
				dataLayer['modelName']	= $(this).data('model-name');
				dataLayer['modelCode']	= $(this).data('model-id');
				dataLayer['salesModelCode'] = $(this).data('model-salesmodelcode');
				dataLayer['sku'] = $(this).data('sku');
				dataLayer['suffixCode']  = $(this).data('model-suffixcode');
				dataLayer['modelId'] = $(this).data('model-id');
				dataLayer['bu'] = $(this).data('bu');
				dataLayer['price'] = $(this).data('price');
				dataLayer['currencyCode']  = $('.currency-code').val();
				dataLayer['dimension185'] = $('.navigation').attr('data-obs-group');
				dataLayer['metric4'] = $(this).attr('data-msrp');
				
				
				
				// adobe
				if($('.GPC0009').length>0) {
					var pid2 = $('.GPC0009').data('product-id');
					var modelId = $(this).data('model-id');
					if(pid2 != modelId) {
						adobeTrackEvent('re-stock-alert', {
							products: [{
								sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
								model_name: findModelName($(this))
							}], cross_sell_product :pid2, page_event: {re_stock_alert: true}
						});
					} else {
						adobeTrackEvent('re-stock-alert', {
							products: [{
								sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
								model_name: findModelName($(this))
							}], page_event: {re_stock_alert: true}
						});
					}
				} else {
					adobeTrackEvent('re-stock-alert', {
						products: [{
							sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
							model_name: findModelName($(this))
						}], page_event: {re_stock_alert: true}
					});
				}
				$('body').trigger('ajaxLoadBefore');
				reStockAlert.callReStockAlert(url, dataLayer);
			});
		}
	};
	reStockAlert.init();
	// PJTOBS 20200702 End
	
	// OBSLGEIN-564 Start
	exchangeProgram = {
		autoValidationForm: function() {
			$('#exchange_product .exchange-program-pincode-form, .exchange-program-category-form, .exchange-program-type-form, .exchange-program-brand-form, .exchange-program-price-form').on({
				'ajaxLoadBefore':function(e){
					e.stopPropagation();
					$(this).append('<div class="loading-circle"><div class="lds-dual-ring"></div></div>');
				},
				'ajaxLoadEnd':function(e){
					e.stopPropagation();
					$(this).find('.loading-circle').remove();
				}
			});
			// Pincode
			$('#exchangeProgramPincodeForm').on({
				submit: function(e){
					e.preventDefault();
					$('#exchange_product .exchange-program-pincode-form').trigger('ajaxLoadBefore');
					var $this = $(this);
					if(!$this.checkValidation()){
						$('#exchange_product .exchange-program-pincode-form').trigger('ajaxLoadEnd');
					}else {
						var url = $this.data('url');
						var param = $this.serialize();
						ajax.noCacheCall(url, param, 'json', function (data) {
							$('#exchange_product .exchange-program-pincode-form').trigger('ajaxLoadEnd');
							var pinFailHTML = "<span class=\"check-fail-exchange\">Exchange not available in your area</span>";
							var pinSuccessHTML = "<div class=\"check-good-exchange\"><p class=\"pincode-ok\">Exchange available in your area</p></div>";
							if (data.returnCode) {
								var rCode = data.returnCode;
								if(rCode=='00') { // 성공
									var categoryList = data.categoryList;
									var categoryButton="";
									$.each(categoryList, function(index, item){
										categoryButton += "<button type=\"button\" class=\"chk-item exchangeProgramCategorySubmit\" value="+item.Id+">"+item.Description+"</button>";
									})
									$('#exchange_product .exchange-program-category-form .chk-item-box').html(categoryButton);
									$('#sec_step_1').addClass('is-show');
									if(!$('.navigation').hasClass('mobile-device')) {
										$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', $('#sec_step_1'), {scrollInertia: 300});
									} else {
										var movePosY = $('#sec_step_1').position().top;
										$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
									}
									$('#exchange_product .exchange-program-pincode-form .error-msg').show().html(pinSuccessHTML);
								} else { // 실패
									$('#exchange_product .exchange-program-pincode-form .error-msg').show().html(pinFailHTML);
								}
							} else {
								$('#exchange_product .exchange-program-pincode-form .error-msg').show().html(pinFailHTML);
							}
						}, 'post', '#exchange_product .exchange-program-pincode-form');
					}
				}
			});
			$(document).on('keypress', '.inp-pincode', function(e) {
				if(e.keyCode == '13'){
					e.preventDefault();
					//선택 시 기존 선택값 초기화
					exchangeProgram.reSelectInit();
					$('.chk-step-li').removeClass('is-show');
					$('.chk-completion').removeClass('is-completed');
					$('input[name=exc_step_4]').prop('checked', false);
					$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
					$('#exchangeProgramPincodeForm .error-msg').empty();
					$('#exchangeProgramPincodeForm').trigger('submit');
				}
			});
			$(document).on('click', '#exchangeProgramPincodeSubmit', function(e) {
				e.preventDefault();
				//선택 시 기존 선택값 초기화
				exchangeProgram.reSelectInit();
				$('.chk-step-li').removeClass('is-show');
				$('.chk-completion').removeClass('is-completed');
				$('input[name=exc_step_4]').prop('checked', false);
				$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
				$('#exchangeProgramPincodeForm .error-msg').empty();
				$('#exchangeProgramPincodeForm').trigger('submit');
			});
			// Category
			var typeA = [];
			var typeB = []; 
			$(document).on('submit', '#exchangeProgramCategoryForm', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				$('#exchange_product .exchange-program-category-form').trigger('ajaxLoadBefore');
				var $this = $(this);
				if(!$this.checkValidation()){
					$('#exchange_product .exchange-program-category-form').trigger('ajaxLoadEnd');
				}else {
					var url = $this.data('url');
					var param = $this.serialize();
					typeA = [];
					typeB = [];
					ajax.noCacheCall(url, param, 'json', function (data) {
						$('#exchange_product .exchange-program-category-form').trigger('ajaxLoadEnd');
						if (data.returnCode) {
							var rCode = data.returnCode;
							if(rCode=='00') { // 성공
								var typeList = data.typeList;
								var typeButton = "";
								$.each(typeList, function(index, item){
									if(typeof item.Description.split(" (")[1] ==='undefined' || item.ProductCatId == 2){
										typeB.push({
											"type1":item.Description.split(" (")[0],
											"type2":null,
											"value":item.Id
											});
									}else{
										typeB.push({
											"type1":item.Description.split(" (")[0],
											"type2":"("+item.Description.split(" (")[1],
											"value":item.Id
											});
										
									}
									//console.log(typeB);
									if(!typeA.includes(item.Description.split(" (")[0])){
										if(typeof item.Description.split(" (")[1] ==='undefined' || item.ProductCatId == 2){
											typeButton += "<button type=\"button\" class=\"chk-item exchangeProgramTypeSubmit branding\" value=\""+item.Id+"\">"+item.Description.split(" (")[0]+"</button>"
										}else{
											typeButton += "<button type=\"button\" class=\"chk-item exchangeProgramTypeSubmit\" value=\""+item.Description.split(" (")[0]+"\">"+item.Description.split(" (")[0]+"</button>"
										}
										typeA.push(item.Description.split(" (")[0])
									}
								
								})
								$('#exchange_product .exchange-program-type-form .chk-item-box').html(typeButton);
								$('#sec_step_2').addClass('is-show');
								if(!$('.navigation').hasClass('mobile-device')) {
									$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', $('#sec_step_2'), {scrollInertia: 300});
								} else {
									var movePosY = $('#sec_step_2').position().top;
									$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
								}
							} else { // 실패
								console.log('exchange program fail');
							}
						} else {
							console.log('exchange program fail');
						}
					}, 'post', '#exchange_product .exchange-program-category-form');
				}
			});
			$(document).on('click', '.exchangeProgramCategorySubmit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				//선택 시 기존 선택값 초기화
				exchangeProgram.reSelectInit();
				$(this).parent().find('button').removeClass('is-selected');
				$(this).addClass('is-selected');
				if($(this).text()=='Refrigerator'){
					$('#condition-excellent').siblings('.ex').html($('#exchangeProgramConditionRF1').val());
					$('#condition-good').siblings('.ex').html($('#exchangeProgramConditionRF2').val());
					$('#condition-averge').siblings('.ex').html($('#exchangeProgramConditionRF3').val());
				} else if($(this).text()=='Washing Machine'){
					$('#condition-excellent').siblings('.ex').html($('#exchangeProgramConditionWM1').val());
					$('#condition-good').siblings('.ex').html($('#exchangeProgramConditionWM2').val());
					$('#condition-averge').siblings('.ex').html($('#exchangeProgramConditionWM3').val());
				} else if($(this).text()=='Television'){
					$('#condition-excellent').siblings('.ex').html($('#exchangeProgramConditionTV1').val());
					$('#condition-good').siblings('.ex').html($('#exchangeProgramConditionTV2').val());
					$('#condition-averge').siblings('.ex').html($('#exchangeProgramConditionTV3').val());
				} else if($(this).text()=='Air Conditioner'){
					$('#condition-excellent').siblings('.ex').html($('#exchangeProgramConditionAC1').val());
					$('#condition-good').siblings('.ex').html($('#exchangeProgramConditionAC2').val());
					$('#condition-averge').siblings('.ex').html($('#exchangeProgramConditionAC3').val());
				}
				$('#sec_step_2, #sec_step_3, #sec_step_4').removeClass('is-show');
				$("#sec_step_2").find(".typeB").remove();
				$('#sec_step_2').find('.typeB_li').remove();
				$('.chk-completion').removeClass('is-completed');
				$('input[name=exc_step_4]').prop('checked', false);
				$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
				var categoryId = "<input type=\"hidden\" id=\"categoryId\" name=\"categoryId\" value="+$(this).val()+">";
				if($('#exchangeProgramCategoryForm #categoryId').length>=1){
					$('#exchangeProgramCategoryForm #categoryId').val($(this).val());
				}else{
					$('#exchangeProgramCategoryForm').append(categoryId);
				}
				
				//$('#exchangeProgramCategoryForm').html(categoryId);
				$('#exchangeProgramCategoryForm').trigger('submit');
			});
			// Type
			$(document).on('submit', '#exchangeProgramTypeForm', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				$('#exchange_product .exchange-program-type-form').trigger('ajaxLoadBefore');
				var $this = $(this);
				if(!$this.checkValidation()){
					$('#exchange_product .exchange-program-type-form').trigger('ajaxLoadEnd');
				}else {
					var url = $this.data('url');
					var param = $this.serialize();
					ajax.noCacheCall(url, param, 'json', function (data) {
						$('#exchange_product .exchange-program-type-form').trigger('ajaxLoadEnd');
						if (data.returnCode) {
							var rCode = data.returnCode;
							if(rCode=='00') { // 성공
								if($("#sec_step_2_1").length > 0){
									var step3TItle = $("#sec_step_3 .tit").html();
									step3TItle = step3TItle.replace("Step 3","Step 4")
									$("#sec_step_3 .tit").html(step3TItle);
								}else{
									var step3TItle = $("#sec_step_3 .tit").html();
									step3TItle = step3TItle.replace("Step 4","Step 3")
									$("#sec_step_3 .tit").html(step3TItle);
								}
								var brandList = data.brandList;
								var brandButton = "";
								$.each(brandList, function(index, item){
									if(item.Name=="LG" || item.Name=="Samsung" || item.Name=="Others"){
										brandButton += "<button type=\"button\" class=\"chk-item exchangeProgramBrandSubmit\" value="+item.Id+">"+item.Name+"</button>"
									}
								})
								$('#exchange_product .exchange-program-brand-form .chk-item-box').html(brandButton);
								$('#sec_step_3').addClass('is-show');
								if(!$('.navigation').hasClass('mobile-device')) {
									$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', $('#sec_step_3'), {scrollInertia: 300});
								} else {
									var movePosY = $('#sec_step_3').position().top;
									$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
								}
							} else { // 실패
								console.log('exchange program fail');
							}
						} else {
							console.log('exchange program fail');
						}
					}, 'post', '#exchange_product .exchange-program-type-form');
				}
			}
			);
			$(document).on('click', '.exchangeProgramTypeSubmit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				//선택 시 기존 선택값 초기화
				exchangeProgram.reSelectInit();
				var type1 = $(this).val();
				var typeBButton ="";
				var branding = $(this).hasClass("branding")? true : false;
				if(!$(this).parent().hasClass("typeB")){
					$("#sec_step_2").find(".typeB").remove();
					$('#sec_step_2').find('.typeB_li').remove();
				}
				
				if(!branding){
					typeB.forEach(function(i,inx){
						if(i.type1===type1){
							var typeButtonName = i.type2;
							//이름에서 괄호 제거
							typeButtonName = typeButtonName.replace(/[()]/g, '');
							typeBButton += "<button type=\"button\" class=\"chk-item exchangeProgramTypeSubmit branding\" value=\""+i.value+"\">"+typeButtonName+"</button>"
						}
					})
				
					var exchangeProgramStepTitle2_1_rf = $('#exchangeProgramStepTitle2-1-rf').val();
					var exchangeProgramStepTitle2_1_tv = $('#exchangeProgramStepTitle2-1-tv').val();
					//$("#sec_step_2").find(".chk-item-box").after("<div class=\"chk-item-box typeB mt-4\">"+typeBButton+"</div>");
					if($('.exchangeProgramCategorySubmit.is-selected').text()=="Refrigerator"){
						$("#sec_step_2").find(".chk-item-box").after("<li class=\"chk-step-li is-show typeB_li\" id=\"sec_step_2_1\"><strong class=\"tit\">"+exchangeProgramStepTitle2_1_rf+"</strong><div class=\"chk-item-box typeB\">"+typeBButton+"</div></li>");
					} else if($('.exchangeProgramCategorySubmit.is-selected').text()=="Television"){
						$("#sec_step_2").find(".chk-item-box").after("<li class=\"chk-step-li is-show typeB_li\" id=\"sec_step_2_1\"><strong class=\"tit\">"+exchangeProgramStepTitle2_1_tv+"</strong><div class=\"chk-item-box typeB\">"+typeBButton+"</div></li>");
					}
					if(!$('.navigation').hasClass('mobile-device')) {
						$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', $('#sec_step_2_1'), {scrollInertia: 300});
					} else {
						var movePosY = $('#sec_step_2_1').position().top;
						$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
					}
				}
				$(this).parent().find('button').removeClass('is-selected');
				$(this).addClass('is-selected');
				$('#sec_step_3, #sec_step_4').removeClass('is-show');
				$('.chk-completion').removeClass('is-completed');
				$('input[name=exc_step_4]').prop('checked', false);
				$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
				if(branding){
					var categoryId = "<input type=\"hidden\" id=\"categoryId\" name=\"categoryId\" value="+$('#exchangeProgramCategoryForm input[name=categoryId]').val()+">";
					var typeId = "<input type=\"hidden\" id=\"typeId\" name=\"typeId\" value="+$(this).val()+">";
					if($('#exchangeProgramTypeForm #categoryId').length>=1){
						$('#exchangeProgramTypeForm #categoryId').val($('#exchangeProgramCategoryForm input[name=categoryId]').val());
					}else{
						$('#exchangeProgramTypeForm').append(categoryId);
					}
					if($('#exchangeProgramTypeForm #typeId').length>=1){
						$('#exchangeProgramTypeForm #typeId').val($(this).val());
					}else{
						$('#exchangeProgramTypeForm').append(typeId);
					}
					//$('#exchangeProgramTypeForm').html(categoryId+typeId);
					$('#exchangeProgramTypeForm').trigger('submit');
				}
			});
			// Brand
			$(document).on('submit', '#exchangeProgramBrandForm', function(e){
				e.preventDefault();
				e.stopImmediatePropagation();
				$('#exchange_product .exchange-program-brand-form').trigger('ajaxLoadBefore');
				var $this = $(this);
				if(!$this.checkValidation()){
					$('#exchange_product .exchange-program-brand-form').trigger('ajaxLoadEnd');
				}else {
					var url = $this.data('url');
					var param = $this.serialize();
					ajax.noCacheCall(url, param, 'json', function (data) {
						$('#exchange_product .exchange-program-brand-form').trigger('ajaxLoadEnd');
						if (data.returnCode) {
							var rCode = data.returnCode;
							if(rCode=='00') { // 성공
								if($("#sec_step_2_1").length > 0){
									var step4TItle = $("#sec_step_4 .tit").html();
									step4TItle = step4TItle.replace("Step 4","Step 5")
									$("#sec_step_4 .tit").html(step4TItle);
								}else{
									var step4TItle = $("#sec_step_4 .tit").html();
									step4TItle = step4TItle.replace("Step 5","Step 4")
									$("#sec_step_4 .tit").html(step4TItle);
								}
								var priceList = data.priceList[0];
								var excellentPrice = priceList.Price;
								var goodPrice = priceList.Mid_Price;
								var avergePrice = priceList.Min_Price;
								$('#condition-excellent').attr('value', excellentPrice);
								$('#condition-good').attr('value', goodPrice);
								$('#condition-averge').attr('value', avergePrice);
								var exchangeModelName = data.modelName;
								if($('#exchangeProgramBrandForm #exModelName').length>=1){
									$('#exchangeProgramBrandForm #exModelName').val(exchangeModelName);
								}else{
									$('#exchangeProgramBrandForm').append("<input type=\"hidden\" id=\"exModelName\" name=\"exModelName\" value=\""+exchangeModelName+"\">");
								}
								$('.txt-prd .fc-carmine').html(exchangeModelName);
								$('#sec_step_4').addClass('is-show');
								if(!$('.navigation').hasClass('mobile-device')) {
									$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', $('#sec_step_4'), {scrollInertia: 300});
								} else {
									var movePosY = $('#sec_step_4').position().top;
									$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
								}
							} else { // 실패
								console.log('exchange program fail');
							}
						} else {
							console.log('exchange program fail');
						}
					}, 'post', '#exchange_product .exchange-program-brand-form');
				}
			});
			$(document).on('click', '.exchangeProgramBrandSubmit', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				//선택 시 기존 선택값 초기화
				exchangeProgram.reSelectInit();
				$(this).parent().find('button').removeClass('is-selected');
				$(this).addClass('is-selected');
				$('#sec_step_4').removeClass('is-show');
				$('.chk-completion').removeClass('is-completed');
				$('input[name=exc_step_4]').prop('checked', false);
				$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
				var categoryId = "<input type=\"hidden\" id=\"categoryId\" name=\"categoryId\" value="+$('#exchangeProgramTypeForm input[name=categoryId]').val()+">";
				var typeId = "<input type=\"hidden\" id=\"typeId\" name=\"typeId\" value="+$('#exchangeProgramTypeForm input[name=typeId]').val()+">";
				var brandId = "<input type=\"hidden\" id=\"brandId\" name=\"brandId\" value="+$(this).val()+">";
				if($('#exchangeProgramBrandForm #categoryId').length>=1){
					$('#exchangeProgramBrandForm #categoryId').val($('#exchangeProgramTypeForm input[name=categoryId]').val());
				}else{
					$('#exchangeProgramBrandForm').append(categoryId);
				}
				if($('#exchangeProgramBrandForm #typeId').length>=1){
					$('#exchangeProgramBrandForm #typeId').val($('#exchangeProgramTypeForm input[name=typeId]').val());
				}else{
					$('#exchangeProgramBrandForm').append(typeId);
				}
				if($('#exchangeProgramBrandForm #brandId').length>=1){
					$('#exchangeProgramBrandForm #brandId').val($(this).val());
				}else{
					$('#exchangeProgramBrandForm').append(brandId);
				}
				//$('#exchangeProgramBrandForm').html(categoryId+typeId+brandId);
				$('#exchangeProgramBrandForm').trigger('submit');
			});
			// price
			$(document).on('click', '.exchangeProgramPriceSubmit', function(e) {
				//선택 시 기존 선택값 초기화
				exchangeProgram.reSelectInit();
				$('.chk-completion').addClass('is-completed');
				if(!$('.navigation').hasClass('mobile-device')) {
					$('#exchange_product').find('.js-scroll').mCustomScrollbar('scrollTo', '.chk-completion', {scrollInertia: 300});
				} else {
					var movePosY = $('.chk-completion').position().top;
					$('#exchange_product').find('.js-scroll').stop().delay(100).animate({scrollTop : movePosY}, 400);
				}
				var checkedPrice = $('input[name=exc_step_4]:checked');
				$('.prd-detail-condition').html(checkedPrice.siblings('.text').text());
				$('.fw-sb .fc-carmine').html("₹"+checkedPrice.val().split('.')[0]);
			});
			// submit 버튼 활성화
			$(document).on('change', 'input[name=exchangePolicyAgreementFlag]', function(){
				if($(this).is(":checked")) {
					$('#exchange_product').find('.modal-footer button[type=submit]').prop('disabled', false);
				} else {
					$('#exchange_product').find('.modal-footer button[type=submit]').prop('disabled', true);
				}
			});
			// add to order 클릭시 데이터 담기
			$('.modal-footer button[type=submit]').on('click', function(){
				var categoryId = $('#exchangeProgramBrandForm input[name=categoryId]').val();
				var typeId = $('#exchangeProgramBrandForm input[name=typeId]').val();
				var brandId = $('#exchangeProgramBrandForm input[name=brandId]').val();
				var bonusPrice = $('input[name=exc_step_4]:checked').val();
				var condition = $('input[name=exc_step_4]:checked').data('condition');
				var exModelName = $('#exchangeProgramBrandForm input[name=exModelName]').val();
				$component.find(".exchange-area .exchange-detail").attr({
					"data-categoryId" : categoryId,
					"data-typeId": typeId,
					"data-brandId": brandId,
					"data-bonusPrice": bonusPrice,
					"data-condition": condition,
					"data-exModelName" : exModelName
				});
				$('.exchange-area .exchange-box').addClass('is-selected');
				$('.exchange-area .exchange-box .price').addClass('d-none');
				$component.find(".exchange-area .exchange-detail .fc-carmine.fw-sb").html("₹"+bonusPrice.split('.')[0]);
				
				var tooltip = $component.find(".exchange-area .exchange-detail .tooltip-inner .desc");
				tooltip.html("<strong></strong><ul class=\"ex\"></ul>");
				$('input[name=exc_step_4]:checked').siblings('.ex').find('li').clone().each(function(index,value){
					tooltip.find('ul').append(value);
				});
				var conditionText = $('input[name=exc_step_4]:checked').siblings('.text').text();
				tooltip.find('strong').html(conditionText);
				$('.exchange-area .more-box .btn-moreBox').focus();
			})
			// reset
			$('.exchange-area .exchange-box .exchange-detail .btn-area .link-text').on('click', function(){
				if($(this).hasClass('btn-cancel')) {
					$('#exc_opt_reset').modal('show');
				} else {
					$('#exchange_product').modal('show');
				}
			});
			$('#exc_opt_reset .modal-footer .btn-primary').on('click', function(){
				$component.find(".exchange-area .exchange-detail").removeAttr('data-categoryid');
				$component.find(".exchange-area .exchange-detail").removeAttr('data-typeid');
				$component.find(".exchange-area .exchange-detail").removeAttr('data-brandid');
				$component.find(".exchange-area .exchange-detail").removeAttr('data-bonusprice');
				$component.find(".exchange-area .exchange-detail").removeAttr('data-condition');
				$component.find(".exchange-area .exchange-detail").removeAttr('data-exmodelName');
				$('#exchangeProgramPincodeForm .error-msg').empty();
				$('.chk-step-li').removeClass('is-show');
				$('.chk-completion').removeClass('is-completed');
				var formList = $('#exchange_product').find('form')
				$.each(formList, function(index, item){
					item.reset();
				});
				$('input[name=exc_step_4]').prop('checked', false);
				$('input[name=exchangePolicyAgreementFlag]').prop('checked', false);
				$('#exchange_product').find('.modal-footer button[type=submit]').prop('disabled', true);
				$('.exchange-area .exchange-box').removeClass('is-selected');
				$('.exchange-area .exchange-box .price').removeClass('d-none');
				$('.exchange-area .more-box .btn-moreBox').focus();
			});
		},reSelectInit:function(){
			$('#exchange_product .easy-step #i_agree_exchange_policy').prop("checked", false);
			$('#exchange_product .modal-footer .btn-primary').attr("disabled",true);
			$component.find(".exchange-area .exchange-detail").removeAttr('data-categoryid');
			$component.find(".exchange-area .exchange-detail").removeAttr('data-typeid');
			$component.find(".exchange-area .exchange-detail").removeAttr('data-brandid');
			$component.find(".exchange-area .exchange-detail").removeAttr('data-bonusprice');
			$component.find(".exchange-area .exchange-detail").removeAttr('data-condition');
			$component.find(".exchange-area .exchange-detail").removeAttr('data-exmodelname');
			$('.exchange-area .exchange-box').removeClass('is-selected');
			$('.exchange-area .exchange-box .price').removeClass('d-none');
		}
	};
	if(typeof $('#exchangeProgramUseFlag') != "undefined") {
		if(null != $('#exchangeProgramUseFlag').val() && "Y" == $('#exchangeProgramUseFlag').val()) {
			exchangeProgram.autoValidationForm();
		}
	}
	// OBSLGEIN-564 End

	// 20200611 START 박지영 - addToCart 를 전역변수로 변경
	addToCart = {
		$nav: $('.navigation').eq(0),
		store: ($('.navigation').eq(0).data('store')) ? $('.navigation').eq(0).data('store') : 'default',
		form: "#addtocartForm",
		cookieName: 'LG5_CartID',
		cartID: null,
		url: ($('header.navigation') && $('header.navigation').data('cart-check')) ?  $('header.navigation').data('cart-check') : "/graphql",
		successMsg: null,
		preOrderSuccessMsg: null,
		successBtnAlert: null,
		successBtnClose: null,
		successHTML: null,
		cookieNotice: null, //LGEGMC-1278
		init: function() {
			// get msg/btn txt (for success)
			this.successMsg = this.$nav.find('.cart').data('alert-success');
			this.preOrderSuccessMsg = this.$nav.find('.cart').data('pre-order-success'); //PJTOBSB2E-3 add
			this.successBtnAlert = this.$nav.find('.cart').data('alert-btn');
			this.successBtnClose = this.$nav.find('.cart').data('close-btn');
			this.cookieNotice = this.$nav.find('.cart').data('cookie-notice-msg'); //LGEGMC-1278
			this.$nav.find('.cart').removeAttr('data-alert-btn').removeAttr('data-alert-success').removeAttr('data-close-btn');
			/*LGEGMC-1210 s*/
			//if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {
				if(OBS_LOGIN_FLAG == 'Y') {
					// get cookie id
					addToCart.cartID = getCookie(addToCart.cookieName);

					// add param to cart url
					if(addToCart.cartID) {
						// get the number of items in the cart
						// 20200611 START 박지영 - IE main 에서 path 추가된 쿠키 잘 안 읽히는 case 예외 처리 
						OBS_Y.getGNBCart();
						// 20200611 END
						// change cart url
						
						//LGEITF-602 START : CART URL CART ID 추가 부분 제거
						//OBS_Y.changeCartUrl(addToCart.cartID);
						//LGEITF-602 END 
						
					} else {
						
						/* 20201012 SSO domain 추가  */
						if(!ISSSO){
							// create the cart id
							// 20200611 START 박지영 - IE main 에서 path 추가된 쿠키 잘 안 읽히는 case 예외 처리 
							//OBS_Y.createEmptyCart(); /* LGEGMC-915 */
						}
						
					}
				} else if(OBS_LOGIN_FLAG == 'S') {
					// get the number of items in the cart
					OBS_S.getGNBCart();
				}
			//}
				/*LGEGMC-1210 e*/
			
			// LGEIN-71
			if($component.length){
				
				/* LGEGMC-1914 START */
				if($component.find('.checkAvailable').length > 0){
					
					$(document).on('change', '#comunaRegionId', $.proxy(OBS_Y.comunaChange, self));
					$(document).on('click', '.checkAvailable a.btn-sm', function (e) {
						e.preventDefault();
						$('body').trigger('ajaxLoadBefore');
						
						var comunaId = $(this).closest('.checkAvailable').find('.check-comuna').val();
						OBS_Y.checkComuna(comunaId);
						
					});
				}else{
					
					$(document).on('click', '.chkStBox a.btn-sm', function (e) {
						e.preventDefault();
						
						$('body').trigger('ajaxLoadBefore');
						
						var sku = $component.find("#pin-modelId").val();
						/*LGCOMAU-104 Start*/
						if(COUNTRY_CODE == 'au' && $(".GPC0009").find(".add-bundle-area").length>0 && $(".GPC0009").find(".add-bundle-area li").length>0){
							$(".add-bundle-area").eq(0).find(".bundle-select").each(function(){
								sku= sku+","+$(this).data("modelid");
							})
						}
						/*LGCOMAU-104 End*/
						// LGEVN-80 START
						var pincode = $(this).closest('.chkStBox').find('.check-pincode').val();
						OBS_Y.checkSale(sku, 1, pincode);
					
					});
				}
		        /* LGEGMC-1914 END */
				
			}

			// PJTOBS-32 Start  (for GPC0010 only, OBS=Y)
			$('body').off('click.addtocart2').on('click.addtocart2', '.add-to-cart-multi', function (e) {
				e.preventDefault();
				var modelId = packageComponent.getMultiModelId();
				if(OBS_LOGIN_FLAG == 'Y') {
					addToCart.cartID = getCookie(addToCart.cookieName);
					/* LGCOMSM-34, LGESA-330 createEmptyCart 로직삭제 */
						var pinModelId = $component.find("#pin-modelId").val();
						
						if(pinModelId){
							if($component.find(".check-good").length>0){
								//LGEIN-586 START
								var pinCode = $component.find(".check-good").data('pin-code');
								// pincode 체크 했을 때
								OBS_Y.addProductToCart(modelId, 1, addToCart.cartID, '', '', pinCode);
								//LGEIN-586 END
							}else{
								$component.find('.pdp-info.desktop-info').find("#check-pincode").focus();
								$component.find('.pdp-info.mobile-bottom-info').find("#check-pincode").focus();
							}
						} else {
							// add to cart
							OBS_Y.addProductToCart(modelId, 1, addToCart.cartID);
						}
					
				}
			});
			// PJTOBS-32 End

			$('body').off('click.addtocart').on('click.addtocart', '.add-to-cart', function (e) {
				var isButton = !($(this).attr('role')!='button' || !OBS_LOGIN_FLAG || (OBS_LOGIN_FLAG!='Y' && OBS_LOGIN_FLAG!='S'));
				if(isButton) e.preventDefault();
				currentFocus = $(this);

				var modelId = !!$(this).attr('data-model-id') ? $(this).attr('data-model-id') : $(this).data('modelId'); // LGEGMC-5131 
				/* LGEGMC-712 : 20200928 modify */
				var datelayer_use_flag = $('.addtocart-datelayer-use-flag').val();
				
				// LGEIN-155
				var obsPinCodeFlag = $('.GPC0009').find('#obsPincodeFlag').val();
				
				// LGEIN-155, LGEVN-80
				if($(this).hasClass('in-buynow') == false && obsPinCodeFlag != 'Y' && datelayer_use_flag != 'Y') {
					// adobe
					if($('.GPC0009').length>0) {
						var pid2 = $('.GPC0009').data('product-id');
						if(pid2 != modelId) {
							adobeTrackEvent('add-to-cart', {
								products: [{
									sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
									model_name: findModelName($(this)),
									value_added_to_cart: findPrice($(this)),
									units_added_to_cart: findModelCount($(this))
								}], cross_sell_product :pid2, page_event: {add_to_cart: true}
							});
						} else {
							adobeTrackEvent('add-to-cart', {
								products: [{
									sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
									model_name: findModelName($(this)),
									value_added_to_cart: findPrice($(this)),
									units_added_to_cart: findModelCount($(this))
								}], page_event: {add_to_cart: true}
							});
						}
					} else {
						adobeTrackEvent('add-to-cart', {
							products: [{
								sales_model_code : findSalesModel($(this)), /* LGEGMC-455 20200722 modify */
								model_name: findModelName($(this)),
								value_added_to_cart: findPrice($(this)),
								units_added_to_cart: findModelCount($(this))
							}], page_event: {add_to_cart: true}
						});
					}
				}
				
				if(!isButton) {
					return true;
				} else {
					/*LGEGMC-1210 s*/
					//if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {

						$('body').trigger('ajaxLoadBefore');
						if(OBS_LOGIN_FLAG == 'Y') {

							// get cookie id
							addToCart.cartID = getCookie(addToCart.cookieName);

							// if($(this).closest('.GPC0010').length>0) {

							// } else {

								// create empty cart (obs)
								/* LGCOMSM-34 ,LGESA-330 createEmptyCart 로직삭제 */
									//LGEIN-71, LGEIN-125, LGEVN-80
									var pinModelId = $component.find("#pin-modelId").val();
									
									if(pinModelId && (COUNTRY_CODE != 'cl' && COUNTRY_CODE != 'pe')) { // LGECL-248,LGCOMPE-74
										if($component.find(".check-good").length>0){
											//LGEIN-586 START
											var pinCode = $component.find(".check-good").data('pin-code');
											/* LGEGMC-407 : 20200709 modify */
											OBS_Y.addProductToCart(modelId, 1, addToCart.cartID, true, $(this), pinCode);
											/*// LGEGMC-407 : 20200709 modify */
											//LGEIN-586 END
										}else{
											
											/* LGEGMC-1914 modify */
											if($component.find('.checkAvailable').length > 0){
												
												/* OBSLGEPE-142,LGCOMPE-74 START */
												/*if(COUNTRY_CODE == 'pe'){
												
													if(!$('#ubigeoError').length){
														var ubigeoErrorModalText = $('.GPC0009 .pdp-summary-area').data('ubigeo-error-modal-text');
														var okText = $('.GPC0009 .pdp-summary-area').data('pincode-ok');
														var message = !!ubigeoErrorModalText ? ubigeoErrorModalText : 'Please choose your ubigeo first.';
														var ok = !!okText ? okText : 'OK';
														
														var modalHtml = '<div class="modal modal-simple fade" id="ubigeoError" tabindex="-1" role="dialog" data-backdrop="false" aria-describedby="ubigeoErrorDialog">'; 
														modalHtml += 	    '<div class="modal-dialog" role="document">';
														modalHtml +=	        '<div class="modal-content">';
														modalHtml +=	            '<div class="modal-body">';
														modalHtml +=	                '<div class="simple-content-box"><div class="content-paragraph" id="ubigeoErrorDialog">'+message+'</div></div>';
														modalHtml +=		        '</div>';
														modalHtml +=		        '<div class="modal-footer">';
														modalHtml +=	                '<button type="button" class="btn btn-primary" data-dismiss="modal">'+ok+'</button>';
														modalHtml +=	            '</div>';
														modalHtml +=	        '</div>';
														modalHtml +=	    '</div>';
														modalHtml +=	'</div>';
														
														$('body').append(modalHtml);
													}
													$('#ubigeoError').modal();
													
												}*/
												/* OBSLGEPE-142,LGCOMPE-74 END */
												
												$('#comunaRegionId').trigger('chosen:activate');
												$component.find(".check-region").focus();
											} else {
												
												/* LGEGMC-2839 Start */
												if(!$('#pincodeError').length){
													var errorText = $('.GPC0009 .pdp-summary-area').data('pincode-error-text');
													var okText = $('.GPC0009 .pdp-summary-area').data('pincode-ok');
													var message = !!errorText && errorText != 'component-pincode-error-text' ? errorText : 'Please verify your pincode first.';
													var ok = !!okText ? okText : 'OK';
													
													var modalHtml = '<div class="modal modal-simple fade" id="pincodeError" tabindex="-1" role="dialog" data-backdrop="false" aria-describedby="pincodeErrorDialog">'; 
													modalHtml += 	    '<div class="modal-dialog" role="document">';
													modalHtml +=	        '<div class="modal-content">';
													modalHtml +=	            '<div class="modal-body">';
													modalHtml +=	                '<div class="simple-content-box"><div class="content-paragraph" id="pincodeErrorDialog">'+message+'</div></div>';
													modalHtml +=		        '</div>';
													modalHtml +=		        '<div class="modal-footer">';
													modalHtml +=	                '<button type="button" class="btn btn-primary" data-dismiss="modal">'+ok+'</button>';
													modalHtml +=	            '</div>';
													modalHtml +=	        '</div>';
													modalHtml +=	    '</div>';
													modalHtml +=	'</div>';
													
													$('body').append(modalHtml);
													
													$(document).on('hidden.bs.modal', '#pincodeError', function(e) {
														if($(".GPC0009").hasClass("before")){
															if($component.find(".delivery-list .option-list").length > 0) {
																if(COUNTRY_CODE=='vn'){
																	$('#pinCodeList').trigger('chosen:open');
																}else{
																	$('#pinCodeList').trigger('chosen:activate');
																}
																$component.find(".check-pincode").focus();
															} else {
																$component.find(".check-pincode").focus();
															}
														}else if($(".GPC0009").hasClass("improve")){
															if($component.find(".checkStock-list .option-list").length > 0) {
																/*LGCOMVN-289 start*/
																if(COUNTRY_CODE=='vn'){
																	if(isMobile || $(window).width() < 767){
																		$(".mobile-bottom-info #pinCodeList").trigger('chosen:open');
																	}else{
																		$('#pinCodeList').trigger('chosen:open');
																	}
																}else{
																	$('#pinCodeList').trigger('chosen:activate');
																}
																if(isMobile || $(window).width() < 767){
																	$('html, body').stop().animate({scrollTop: $('.mobile-bottom-info .checkStock-area').offset().top}, 100);
																}else{
																	$component.find(".check-pincode").focus(); 
																}
																/*LGCOMVN-289 end*/
															} else {
																$component.find(".check-pincode").focus();
															}
														}
													});
												}
												$('#pincodeError').modal();
												/* LGEGMC-2839 End */

											}
											$('body').trigger('ajaxLoadEnd');
											/* LGEGMC-1914 modify */
											
										}
									} else {
										/* LGEGMC-712 : 20200928 modify */
										if(datelayer_use_flag == 'Y'){
											OBS_Y.addProductToCart(modelId, 1, addToCart.cartID, true, $(this));
										} else{		// add to cart
											OBS_Y.addProductToCart(modelId, 1, addToCart.cartID);
											}
										/* LGEGMC-712 : 20200928 modify */

									}
									
								
							// }
						} else if(OBS_LOGIN_FLAG == 'S') {

							OBS_S.addProductToCart(modelId, 1);

						} else {
							console.log('error!');
							return true;
						}
					//} else {
						//ePrivacyCookies.view('click');
					//}
						/*LGEGMC-1210 e*/
				}
			});
		},
		setCartNum: function(cnt) {
			if (cnt > 0) {
				if (cnt >= 100) {
					addToCart.$nav.find('.for-mobile .right .cart .count, .for-desktop .right-btm .icons .cart .count').html('99+');
				} else {
					addToCart.$nav.find('.for-mobile .right .cart .count, .for-desktop .right-btm .icons .cart .count').html(cnt);
				}
			} else {
				addToCart.$nav.find('.for-mobile .right .cart .count, .for-desktop .right-btm .icons .cart .count').html('');
			}
		},
		currentFocus: function() {
			if(currentFocus && currentFocus.closest('.component').hasClass('GPC0003')) {
				currentFocus.closest('.item').addClass('on-focus');
				if(currentFocus) currentFocus.focus();
			} else {
				if(currentFocus) currentFocus.focus();
			}
		}
	};
	if((OBS_LOGIN_FLAG=='Y' && ISMAIN && ISMS) || (OBS_LOGIN_FLAG=='Y' && ISSSO)) {
		// 여기에서 실행 되지 않은 addToCart.init() 함수는 쿠키값을 json에서 받아온 후 common.js의 addHomeCookie 함수에서 실행 함.
		/* 20201012 SSO domain 추가  */
	} else {
		addToCart.init();
	}
	// 20200611 END

	// Exciting Offers for you (GPC0003 or GPC0004)
	OBS = {
		timeOut: 5000,
		useCookie: true,
		url: addToCart.$nav.data('cart-check'),
		authToken: OBS_Y.getToken(),
		cookieName: 'LG5_ExcitingOffer',
		init: function($obj) {
			/*LGEGMC-1210 s*/
			//if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {
				//var thisType = $obj.find('form input[name=type]').val();
				var cookieValue = getCookie(OBS.cookieName);
				var orderProduct='';
				if(cookieValue && cookieValue!='') {
					orderProduct = OBS.findCookieValue('ORDER');
				}
				if(OBS_LOGIN_FLAG=='Y') {
					// 통합 OBS
					$obj.trigger('ajaxLoadBefore');
					if(orderProduct) {
						OBS.doSubmit($obj, orderProduct);
					} else {
						OBS.getOrderHistory($obj);
					}
				} else if(OBS_LOGIN_FLAG=='S') {
					// standalone OBS
					$obj.trigger('ajaxLoadBefore');
					OBS.doSubmit($obj, '');
				} else {
					// ETC
					$obj.trigger('ajaxLoadBefore');
					OBS.doSubmit($obj, '');
				}
			/*} else {
				ePrivacyCookies.view('load', '', $obj);
			}*/
				/*LGEGMC-1210 e*/
		},
		findCookieValue: function(name) {
			var cookieText = getCookie(OBS.cookieName);
			var cookieArray = {};
			var product='';
			if(cookieText && cookieText.indexOf(name)!=-1) {
				if(cookieText.indexOf(',')!=-1) {
					var p1 = cookieText.split(',');
					for(var i=0;i<p1.length;i++) {
						cookieArray[p1[i].split(':')[0]] = p1[i].split(':')[1];
					}
				} else {
					var p2 = cookieText;
					cookieArray[p2.split(':')[0]] = p2.split(':')[1];
				}
			}
			product= cookieArray[name];
			if(!product) product='';
			return product;
		},
		addCookieValue: function(name, val) {
			var cookieText = getCookie(OBS.cookieName);
			var cookieArray = {};
			if(cookieText && cookieText.indexOf(name)!=-1) {
				if(cookieText.indexOf(',')!=-1) {
					var p1 = cookieText.split(',');
					for(var i=0;i<p1.length;i++) {
						cookieArray[p1[i].split(':')[0]] = p1[i].split(':')[1];
					}
				} else {
					var p2 = cookieText;
					cookieArray[p2.split(':')[0]] = p2.split(':')[1];
				}
			}
			cookieArray[name] = val;
			console.log(cookieArray);

			cookieText = '';
			$.each(cookieArray, function(key, value) {
				if(cookieText=='') cookieText = cookieText + key + ':' + value;
				else cookieText = cookieText + ',' + key + ':' + value;
			});
			/*LGEGMC-1210 s*/
			//if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')) {
				setCookie(OBS.cookieName, cookieText);
			//}
			/*LGEGMC-1210 e*/
		},
		delCookieValue: function() {
			removeCookie(OBS.cookieName, true);
		},
		doSubmit : function($obj, purchaseModelId) {
			if(purchaseModelId) {
				$obj.find('form input[name=purchaseModelId]').val(purchaseModelId);
			}
			var cartModelId = '';
			var cookieValue = getCookie(OBS.cookieName);
			if(cookieValue && cookieValue!='') {
				cartProduct = OBS.findCookieValue('CART');
			}
			if(cartModelId) {
				$obj.find('form input[name=cartModelId]').val(cartModelId);
			}
			var compareProduct='';
			var cp = getCookie('LG5_CompareCart');
			if(cp) {
				if(cp.indexOf('|')!=-1) {
					//compareProduct = cp.split('|')[cp.split('|').length-1];
					compareProduct = cp.split('|')[0];
				} else {
					compareProduct = cp;
				}
				$obj.find('form input[name=compareModelId]').val(compareProduct);
			}
			var recentlyProduct='';
			var rp = getCookie('LG5_RecentlyView');
			if(rp) {
				if(rp.indexOf('|')!=-1) {
					//recentlyProduct = rp.split('|')[rp.split('|').length-1];
					recentlyProduct = rp.split('|')[0];
				} else {
					recentlyProduct = rp;
				}
				$obj.find('form input[name=recentlyModelId]').val(recentlyProduct);
			}
			$obj.find('form').submit();
		},
		getOrderHistory: function($obj) {
			if(OBS.authToken) {
				var headers = {};
				headers.Authorization = "Bearer " + OBS.authToken;
				headers.Store = addToCart.$nav.data('store');
				headers["Content-type"] = "application/json";
				$.ajax({
					url: OBS.url,
					headers: headers,
					type: 'post',
					data: JSON.stringify({'query': '{customerOrders(pageSize: 2 currentPage: 1) {items {id increment_id created_at status billing_address {firstname lastname postcode street city region {region_code} country_id telephone} shipping_address {firstname lastname postcode street city region {region_code} country_id telephone} items {sku qty_ordered price}}}}', 'variables':null, 'operationName':null}),
					dataType: 'json',
					timeout: OBS.timeOut,
					success: function(data) {
						if(data.data.customerOrders==null) {
							OBS.doSubmit($obj, '');
						} else {
							var len = data.data.customerOrders.items.length;
							if(len>0) {
								var len2 = data.data.customerOrders.items[len-1].items.length;
								if(len2>0) {
									var product = data.data.customerOrders.items[len-1].items[len2-1].sku;
									OBS.addCookieValue('ORDER', product);
									OBS.doSubmit($obj, product);
								} else {
									OBS.doSubmit($obj, '');
								}
							} else {
								OBS.doSubmit($obj, '');
							}
						}
					},
					error: function(request, status, error) {
						 OBS.doSubmit($obj, ''); 
					}
				});
			} else {
				OBS.doSubmit($obj, ''); 
			}
		}
	};
	
	/* LGEGMC-407 : 20200709 add , LGEGMC-712 : 20201102 ADD */
	$('body').on('click', '.add-to-cart', function () {
	var _this = $(this);
	var price = "";
	var displayName="";
	price = $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-price') :_this.attr('data-price');
	if($(_this).closest('.GPC0011').length){
	_this = $('.GPC0009').find('.button a.add-to-cart');
	}
	if($('.match-product-list').length){
		displayName = nvl_def(_this.parents('.product-summary').find('.user-friendly-name').text());
	} else if($('.GPC0009').length || $('.GPC0102').length ){
		displayName = nvl_def($('.pdp-summary-area .pdp-info.desktop-info').find('.model-title').text());
	} else{
		displayName = nvl_def(_this.parents('.item').find('.model-name').text());
	}
	if(price == '.' || price == '.00' || price == '0.0' || price == '0'){
		price = "";
	} 
	var msrp = $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-msrp') : _this.attr('data-msrp');
	if(msrp == '.' || msrp == '.00' || msrp == '0.0' || msrp == '0'){
		msrp = "";
	} 
	ga360Init = {
		event				: 'addToCart',
		pageType 			: $('.GPC0009').length ? 'pdp' : 'nonPdp',
		displayName			: displayName,
		
		siteType			: (typeof standardData === "undefined") ? '' : standardData.siteType,
		std_lv1				: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-buname-one') :_this.attr('data-buname-one'),
		std_lv2				: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-buname-two') :_this.attr('data-buname-two'),
		std_lv3				: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-buname-three') :_this.attr('data-buname-three'),
		
		price               :  price,
		pagePurpose 		: _dl.page_name.page_purpose,
		sku					: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-sku') :_this.attr('data-sku'),
		bu 					: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-buname-one') :_this.attr('data-buname-one'),
		country_code 		: _dl.country_code,
		page_category_l1	: _dl.page_category_l1,
		page_category_l2	: _dl.page_category_l2,
		page_category_l3	: _dl.page_category_l3,
		page_category_l4	: _dl.page_category_l4,
		language 			: _dl.language_code,
		superCategory 		: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-super-category-name') :_this.attr('data-super-category-name'),
		primaryCategory 	: _dl.page_name.category,
		subCategory 		: _dl.page_name.sub_category,
		name		 		: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-model-salesmodelcode') :_this.attr('data-model-salesmodelcode'),
		modelCode	 		: $(_this).closest('.GPC0102').length ? $('.GPC0009').find('.add-to-cart').attr('data-model-id') :_this.attr('data-model-id'),
		productDetailArray 	: new Array(),
		currencyCode		: $('.currency-code').val(),
		quantity			: 0,
		cartID              : new Object(),
		ecommerce			: new Object(),
		obsGroup            : $('.navigation').attr('data-obs-group'),
		metric4             : msrp,
		cart_btn            : 'Y',
		
		init: function() {
			//ga360Init.displayName = nvl_def($('.button a.add-to-cart').closest('.iw_viewport-wrapper').find('.pdp-summary-area .pdp-info.desktop-info').find('.model-title').text());
			//ga360Init.name = (typeof _dl.products[0] === "undefined") ? '' : _dl.products[0].sales_model_code;
			//ga360Init.modelCode = (typeof _dl.products[0] === "undefined") ? '' : _dl.products[0].model_code;
			
			//standard.addEvent();
		},
		
		addData: function() {
			dataLayer.push({
		        'event': ga360Init.event,
		        'siteType': nvl(ga360Init.siteType, 'B2C'),
		        'pagePurpose': nvl(ga360Init.pagePurpose, ga360Init.pageType),
		        'ecommerce': ga360Init.ecommerce,
		        'cartID' : ga360Init.cartID
		    });
			digitalDataLayer.push({
		        'event': ga360Init.event,
		        'siteType': nvl(ga360Init.siteType, 'B2C'),
		        'pagePurpose': nvl(ga360Init.pagePurpose, ga360Init.pageType),
		        'ecommerce': ga360Init.ecommerce,
		        'cartID' : ga360Init.cartID
		    });
		}
		/*
		addEvent: function() {
				$(document).on("mousedown",'.button a.add-to-cart',function(event){
					if ($(this).parents().hasClass('pdp-tab') || $(this).parents().hasClass('pdp-info') || $(this).parents().hasClass('directFrom')) {

					}
				});
		}
		*/
	}
	/* LGEGMC-1342 : 20210302 add */
	if(!_this.hasClass('pre-order')){
		if(_this.closest('.GPC0009').data('track-event-flag') == 'Y' && !!_this.closest('.GPC0009').length && !(!!_this.closest('.GPC0009').find(".check-good").length)){
			adobeTrackEvent('add_to_cart_click_additional', {
				level1 : standardData.level1,
				level2 : standardData.level2,
				level3 : standardData.level3,
				sales_model_code : _dl.products[0].sales_model_code,
				model_name : _dl.products[0].model_name,
				bu : _dl.page_name.bu,
				page_event : {
					add_to_cart_click_additional : true
				}
			});
		}
	}
	/*// LGEGMC-1342 : 20210302 add */
		
	});
	
	

	
	function replaceAll(str, searchStr, replaceStr) {
		
	   	return str.split(searchStr).join(replaceStr);
	};

	function nvl(str, defaultStr){
		var check = str+"";
		var result = "";
		check = check.trim()
		if(check=="" || check==null || check == "null" || check=="undefined"){
			result = defaultStr;
		}else{
			result = check;
		}
		return result ;
	}

	function nvl_def(str){
		var result = str+"";
		result = result.trim()
		if(result=="" || result==null || result == "null" || result=="undefined")
			result = "" ;
		
		result = result.replace(/\s\s+/g, ' ')
		result = replaceAll(result,'_',' ')
		return result ;
	}
	/*// LGEGMC-407 : 20200709 add */
	
	/* PJTEXTENDWTY-4 Start */
	function getFormatDate(date, dateFormat, format){
	      if( typeof(date) === 'object' || typeof(date) === 'undefined' || date == 'null' || date == '' ){ return '-'; }

	      var year = '';
	      var month = '';
	      var day = '';
	      
	      switch(dateFormat){
	        case 'dd/mm/yy':
	        case 'dd-mm-yy':
	        	year = date.substring(6,10);
	        	month = date.substring(3,5);
	        	day = date.substring(0,2);
	        	break;
	        case 'mm/dd/yy':
	        case 'mm-dd-yy':
	        	year = date.substring(6,10);
	        	month = date.substring(0,2);
	        	day = date.substring(3,5);
	        	break;
	        case 'yy/mm/dd':
	        case 'yy-mm-dd':
	        	year = date.substring(0,4);
	        	month = date.substring(5,7);
	        	day = date.substring(8,10);
	        	break;
	        default:
	        	console.log('data Format Error');
	        	return false;
	      }
	      
	      switch(format){
	        case 'YYYY-MM-DD':
	          return  year + '-' + month + '-' + day;
	        case 'YYYY/DD/MM':
	          return  year + '/' + day + '/' + month ;
	        case 'DD/MM/YYYY':
	          return  day + '/' + month + '/' + year ;
	        case 'MM/DD/YYYY':
	          return  month + '/' + day + '/' + year ;
	        default:
	          return  day + '/' + month + '/' + year ;
	      }
	      return false;
    };
    /* PJTEXTENDWTY-4 End */
    
    /* LGCOMIN-98 start */
    var $desktopChkBtn = $component.find('.desktop-info .chkStBox a.btn-sm');
	var $mobileChkBtn  = $component.find('.mobile-bottom-info .chkStBox a.btn-sm');
	const inPincodeLength = 6;
	
	if($('html').data('countrycode').toUpperCase() == 'IN' && $('.GPC0009').length > 0){
		const desktopPinCode = document.querySelector('.desktop-info #check-pincode');
		const mobilePinCode  = document.querySelector('.mobile-bottom-info #check-pincode');
				
		$desktopChkBtn.addClass("disabled");
		$mobileChkBtn.addClass("disabled");
		
		desktopPinCode.maxLength = inPincodeLength;
		mobilePinCode.maxLength  = inPincodeLength;
		
		desktopPinCode.addEventListener("input", (event)=>inPincodeHandleInput(event, $desktopChkBtn));
		mobilePinCode.addEventListener("input",  (event)=>inPincodeHandleInput(event, $mobileChkBtn));
		
		$('.mobile-bottom-info #check-pincode').on('input',function(){
			var moblieInVal = $(this).val();
			if(moblieInVal.length == inPincodeLength){
				$mobileChkBtn.removeClass("disabled");
			}else{
				$mobileChkBtn.addClass("disabled");
			}
		});		
	}
	
	function inPincodeHandleInput(e, chkBtn) {
		/*LGCOMIN-236 s*/
		e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
		/*LGCOMIN-236 e*/
		if (e.target.value.length === 6) {
			chkBtn.removeClass("disabled");
		} else {
			chkBtn.addClass("disabled");            
		}
	}
	/* LGCOMIN-98 end */
});